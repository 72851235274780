import { Box, BoxProps } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { FC } from 'react';

type LoadingProps = {
	text?: string;
	position?: 'absolute' | 'fixed' | 'relative' | 'static' | 'sticky';
	boxProps?: BoxProps;
};
const Loading: FC<LoadingProps> = ({
	text = 'Loading',
	position,
	boxProps,
}) => {
	return (
		<Box
			display="flex"
			justifyContent="center"
			alignItems="center"
			flexGrow={1}
			minHeight="100vh"
			minWidth={position === 'absolute' ? '100vw' : 'auto'}
			position={position}
			zIndex={9999}
			top={0}
			left={0}
			sx={
				position === 'absolute'
					? {
							backgroundColor: 'white',
					  }
					: {}
			}
			{...boxProps}
		>
			<Box sx={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
				<CircularProgress />
				<div>{text}...</div>
			</Box>
		</Box>
	);
};

export default Loading;
