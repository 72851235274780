import React from 'react';
import MuiButton from '@mui/material/Button';
import { ButtonProps } from './types';
import { useTheme } from '@mui/material/styles';

const Button: React.FC<ButtonProps> = ({
	type = 'button',
	children,
	onClick,
	borderRadius,
	color,
	margin,
	props,
	sx,
	startIcon,
	width,
	disabled,
}) => {
	const theme = useTheme();
	const sxValue = sx || {
		background: color || theme.palette.primary.main,
		color: theme.palette.background.default,
		margin: margin,
		width: width,
		borderRadius: borderRadius,
		textTransform: 'none',
		'&:hover': {
			backgroundColor: color || theme.palette.primary.main,
		},
	};
	return (
		<MuiButton
			type={type}
			onClick={onClick}
			{...props}
			sx={sxValue}
			startIcon={startIcon}
			disabled={disabled}
		>
			{children}
		</MuiButton>
	);
};

export default Button;
