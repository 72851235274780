import { Children, FC, ReactNode, memo } from 'react';
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	Checkbox,
	useTheme,
	FormControlLabel,
	IconButton,
} from '@mui/material';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';

type Props = {
	headings: {
		id: string;
		label: string;
		align: 'left' | 'right' | 'center';
		width: string;
		isSortable: boolean;
	}[];
	rows: ReactNode;
	handleSelectAll: (checked: boolean) => void;
	selectAll?: boolean;
	sortBy?: {
		column: string;
		ascending: boolean;
	};
	sortedTable?: (column: string) => void;
	isAllSelected?: boolean;
};

const DataTable: FC<Props> = (props) => {
	const {
		headings,
		rows,
		handleSelectAll,
		selectAll,
		sortedTable,
		sortBy,
		isAllSelected,
	} = props;
	const theme = useTheme();

	return (
		<TableContainer
			component={Paper}
			elevation={3}
			sx={{
				borderRadius: '8px',
				maxHeight: '630px',
				scrollbarWidth: 'thin',
				scrollbarColor: '#7E8081 #CACDCD',
				overflow: 'overlay',
				'&::-webkit-scrollbar': {
					width: '10px',
					height: '10px',
					backgroundColor: '#CACDCD',
				},
				'&::-webkit-scrollbar-thumb': {
					backgroundColor: '#7E8081',
				},
				'&::-webkit-scrollbar-thumb:hover': {
					backgroundColor: '#7E8081',
				},
				'&::-webkit-scrollbar-corner': {
					backgroundColor: '#CACDCD',
				},
			}}
		>
			<Table
				sx={{
					minWidth: 650,
					tableLayout: 'fixed',
				}}
				stickyHeader
				aria-label="simple table"
			>
				<TableHead
					sx={{
						backgroundColor: '#053747',
						color: theme.palette.common.white,
						fontWeight: 'bold',
						'& .MuiTableCell-root': {
							backgroundColor: '#053747',
							color: theme.palette.common.white,
							fontWeight: 'bold',
						},
					}}
				>
					<TableRow style={{ maxHeight: '48px' }}>
						<TableCell style={{ width: '50px' }}>
							{selectAll ? (
								<FormControlLabel
									control={
										<Checkbox
											onChange={(e, checked) => handleSelectAll(checked)}
											checked={isAllSelected}
											sx={{
												color: theme.palette.common.white,

												'&.Mui-checked': {
													color: theme.palette.common.white,
												},
											}}
										/>
									}
									label=""
									sx={{ margin: 0 }}
								/>
							) : null}
						</TableCell>
						{headings.map((heading) => (
							<TableCell
								key={heading.id}
								align={heading.align}
								style={{ width: heading.width }}
								sx={{
									color: theme.palette.common.white,
									fontWeight: 'bold',
									padding: '16px 4px',
								}}
							>
								{heading.label}
								{heading.isSortable && sortedTable && sortBy && (
									<IconButton
										disableRipple
										sx={{ p: 0 }}
										onClick={() => sortedTable(heading.id)}
									>
										{sortBy.column === heading.id && sortBy.ascending ? (
											<ArrowDropUp fontSize="large" sx={{ color: 'white' }} />
										) : (
											<ArrowDropDown fontSize="large" sx={{ color: 'white' }} />
										)}
									</IconButton>
								)}
							</TableCell>
						))}
					</TableRow>
				</TableHead>
				<TableBody
					sx={{
						'& .MuiTableRow-root:nth-of-type(even)': {
							backgroundColor: '#F5F5F5',
						},
						'& .MuiTableRow-root:nth-of-type(odd)': {
							backgroundColor: '#FFFFFF',
						},
						'& .MuiTableRow-root:hover': {
							backgroundColor: '#CACDCD',
						},
					}}
				>
					{rows &&
					Children.toArray(rows).length > 0 &&
					Children.count(rows) > 0 ? (
						rows
					) : (
						<TableRow sx={{ maxHeight: '48px' }}>
							<TableCell colSpan={headings.length + 1}>
								No matches found
							</TableCell>
						</TableRow>
					)}
				</TableBody>
			</Table>
		</TableContainer>
	);
};

export default memo(DataTable);
