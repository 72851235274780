import { Route, Routes } from 'react-router-dom';
import { routes } from './routes';
import ProtectedRoute from './components/molecules/protected-route';
import { useIsAuthenticated } from '@azure/msal-react';
import './App.css';
import { MainLayout } from 'layouts';

const App = () => {
	const isAuthenticated = useIsAuthenticated();
	return (
		<MainLayout>
			{isAuthenticated}
			<Routes>
				{routes.map((route, index) => (
					<Route
						key={index}
						index={route.index}
						path={route.path}
						element={<ProtectedRoute>{route.element}</ProtectedRoute>}
					/>
				))}
				<Route path="/error" element={<div>Oops, Something went wrong</div>} />
				<Route path="*" element={<div>404 - Not Found</div>} />
			</Routes>
		</MainLayout>
	);
};

export default App;
