import { Fragment, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
	Box,
	Button,
	ButtonGroup,
	ToggleButton,
	ToggleButtonGroup,
	FormControlLabel,
	Paper,
	TableRow,
	TableCell,
	Checkbox,
	IconButton,
	Alert,
	Snackbar,
	FormControl,
	InputBase,
	InputAdornment,
	Popover,
	Modal,
	Divider,
	Typography,
	Grid,
	Chip,
} from '@mui/material';
import {
	DeleteOutline,
	AddCircleOutline,
	MailOutline,
	MoreVert,
	Search,
	Edit,
	Close,
	Autorenew,
} from '@mui/icons-material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import moment from 'moment';
import {
	Banner,
	DataTable,
	Dialog,
	UserInviteDialog,
	UserInviteDrawer,
} from 'components/molecules';
import { useFetch } from 'hooks';
import Loading from 'components/atoms/loading';
import Input from 'components/atoms/input/Input';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Config } from 'config';
import { useMsal } from '@azure/msal-react';
import { filterUsersByDomain } from 'helpers/filterADB2CEmails';
import { ADB2CGroupChips } from 'components/molecules/adb2cGroupChips';

type Heading = {
	id: string;
	label: string;
	align: 'left' | 'right' | 'center';
	width: string;
	isSortable: boolean;
};

type User = {
	id: string;
	firstname: string;
	surname: string;
	email: string;
	jobTitle: string;
	department: string;
	companyName: string;
	invitationSendDate?: string;
	invitationStatus?: string;
	companyId?: string;
	modifiedBy?: string;
	createdBy?: string;
	groups: string[];
};

type Group = {
	id: string;
	displayName: string;
	description: string;
	expirationDateTime?: string;
	visibility?: string;
	visibilityEnabled?: boolean;
	members: {
		id: string;
		givenName: string;
		surname: string;
		displayName: string;
		createdDateTime: string;
		jobTitle: string;
		companyName: string;
		userType: string;
	}[];
};

const headings: Heading[] = [
	{
		id: 'firstname',
		label: 'First Name',
		align: 'left',
		width: '110px',
		isSortable: true,
	},
	{
		id: 'surname',
		label: 'Last Name',
		align: 'left',
		width: '110px',
		isSortable: true,
	},
	{
		id: 'email',
		label: 'Email',
		align: 'left',
		width: '222px',
		isSortable: true,
	},
	{
		id: 'department',
		label: 'Location',
		align: 'left',
		width: '110px',
		isSortable: true,
	},
	{
		id: 'companyId',
		label: 'CRM no.',
		align: 'left',
		width: '120px',
		isSortable: true,
	},
	{
		id: 'companyName',
		label: 'Company Name',
		align: 'left',
		width: '150px',
		isSortable: true,
	},
	{
		id: 'invitationSendDate',
		label: 'Invited Date',
		align: 'left',
		width: '110px',
		isSortable: true,
	},
	{
		id: 'service',
		label: 'Service',
		align: 'left',
		width: '175px',
		isSortable: false,
	},
	{
		id: 'modifiedBy',
		label: 'Modified By',
		align: 'left',
		width: '140px',
		isSortable: true,
	},
	{
		id: 'createdBy',
		label: 'Created By',
		align: 'left',
		width: '140px',
		isSortable: true,
	},
	{
		id: 'actions',
		label: '',
		align: 'left',
		width: '50px',
		isSortable: false,
	},
];
/*
function stringToColor(string: string) {
	let hash = 0;
	let i;

	/* eslint-disable no-bitwise */
/*for (i = 0; i < string.length; i += 1) {
		hash = string.charCodeAt(i) + ((hash << 5) - hash);
	}

	let color = '#';

	for (i = 0; i < 3; i += 1) {
		const value = (hash >> (i * 8)) & 0xff;
		color += `00${value.toString(16)}`.slice(-2);
	}
	/* eslint-enable no-bitwise */
/*color = '#04AFC3';
	return color;
}*/
/*
function stringAvatar(value: string): AvatarProps {
	const name = value === 'Client Dashboard' ? 'Client Portal' : value;
	const fullName = name.split(' ');
	if (fullName.length === 1) {
		return {
			sx: {
				bgcolor: stringToColor(name),
				height: '30px',
				width: '30px',
				color: '#000000',
				fontSize: '16px',
				fontWeight: '700',
				lineHeight: '20px',
			},
			children: `${name[0]}` || '',
		};
	} else if (fullName.length >= 2) {
		return {
			sx: {
				bgcolor: stringToColor(name),
				height: '30px',
				width: '30px',
				color: '#000000',
				fontSize: '16px',
				fontWeight: '700',
				lineHeight: '20px',
			},
			children: `${fullName[0][0]}${fullName[1][0]}` || '',
		};
	}
	return {
		sx: {
			bgcolor: stringToColor(name),
			height: '30px',
			width: '30px',
			color: '#000000',
			fontSize: '16px',
			fontWeight: '700',
			lineHeight: '20px',
		},
		children: '',
	};
}*/

const CustomSubmitButton = {
	fontWeight: '400',
	fontSize: '12px',
	lineHeight: '14px',
	color: '#FFFFFF',
	width: '146px',
	height: '35px',
	background: '#003D50',
	margin: '10px',
};
const CustomCanceltButton = {
	fontWeight: '400',
	fontSize: '12px',
	lineHeight: '14px',
	color: '#003D50',
	width: '146px',
	height: '35px',
	margin: '10px',
};

const Home = () => {
	const [filter, setFilter] = useState<'invited' | 'notInvited' | undefined>(
		undefined
	);
	const [search, setSearch] = useState<string>('');
	const [filteredUsers, setFilteredUsers] = useState<User[]>([]);
	const [selected, setSelected] = useState<string[]>([]);
	const [isSendingMail, setIsSendingMail] = useState(false);
	const [successOpen, setSuccessOpen] = useState(false);
	const [snackMessage, setSnackMessage] = useState('');
	const [errorOpen, setErrorOpen] = useState(false);
	const [showDeleteDialog, setShowDeleteDialog] = useState<string | boolean>(
		false
	);
	const [showResetMfaDialog, setShowResetMfaDialog] = useState<
		string | boolean
	>();
	const [showInviteDialog, setShowInviteDialog] = useState<boolean>(false);
	const [showPopover, setShowPopover] = useState<string | undefined>(undefined);
	const [popoverAnchor, setPopoverAnchor] = useState<HTMLElement | null>(null);
	const [editMode, setEditMode] = useState<boolean>(false);
	const [userToEdit, setUserToEdit] = useState<User | undefined>(undefined);
	const [tableSortBy, setTableSortBy] = useState<{
		column: string;
		ascending: boolean;
	}>({
		column: 'firstname',
		ascending: true,
	});
	const { faToken, apimKey, token } = useContext(Config);
	const { accounts } = useMsal();
	const schema = yup.object().shape({
		email: yup
			.string()
			.email('Must be a valid email')
			.max(255)
			.required('Email address is a required field'),
		/*displayName: yup
			.string()
			.max(255)
			.required('Display name is a required field'),*/
		givenName: yup.string().max(255).required('First name is a required field'),
		surname: yup.string().max(255).required('Last name is a required field'),
		companyName: yup
			.string()
			.max(255)
			.required('Company name is a required field'),
		companyId: yup
			.string()
			.max(255)
			.required('CRM account number is a required field'),

		department: yup.string().max(255).required('Location is a required field'),

		groups: yup.array().nullable(),
	});
	const navigate = useNavigate();

	const { fetchLazy, loading: loadingInvite } = useFetch(undefined, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: null,
		onSuccess: () => {
			setIsSendingMail(false);
			setSnackMessage(
				selected.length === 1
					? 'User was successfully invited'
					: `${selected.length} users were successfully invited`
			);
			setShowInviteDialog(false);
			setSuccessOpen(true);
			setSelected([]);
			setSearch('');
			reloadUsers();
		},
		onError: (responseStatus) => {
			setIsSendingMail(false);
			setSnackMessage(`Failed to invite users. ${responseStatus}`);
			setErrorOpen(true);
			reloadUsers();
		},
	});

	const {
		data: users,
		loading: loadingUsers,
		reload: reloadUsers,
	} = useFetch<User[]>('user/users', {
		method: 'GET',
		headers: {
			Authorization: `Bearer ${token}`,
			'Content-Type': 'application/json',
			'x-functions-key': faToken,
			'Ocp-Apim-Subscription-Key': apimKey,
		},
		body: null,
		onSuccess: (data) => {
			setFilteredUsers(data.filter(filterUsersByDomain));
		},
		onError: (responseStatus) => {
			setErrorOpen(true);
		},
	});

	const { fetchLazy: fetchLazyEdit, loading: loadingEdit } = useFetch(
		undefined,
		{
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
			},
			body: null,
			onSuccess: () => {
				setSnackMessage('User was successfully updated');
				setSuccessOpen(true);
				setEditMode(false);
				reloadUsers();
				setSearch('');
			},
			onError: (responseStatus) => {
				setSnackMessage(`Failed to edit user. ${responseStatus}`);
				setErrorOpen(true);
			},
		}
	);

	const { fetchLazy: fetchLazyDelete, loading: loadingDelete } = useFetch(
		undefined,
		{
			method: 'DELETE',
			headers: {
				'Content-Type': 'application/json',
			},
			body: null,
			onSuccess: () => {
				setSnackMessage(
					selected.length <= 1
						? 'User was successfully deleted'
						: `${selected.length} users were successfully deleted`
				);
				setSuccessOpen(true);
				setSelected([]);
				setShowDeleteDialog(false);
				reloadUsers();
				setSearch('');
			},
			onError: (responseStatus) => {
				setSnackMessage(`Failed to delete users. ${responseStatus}`);
				setErrorOpen(true);
			},
		}
	);

	const { fetchLazy: fetchLazyResetMfa, loading: loadingResetMfa } = useFetch(
		undefined,
		{
			method: 'DELETE',
			headers: {
				'Content-Type': 'application/json',
			},
			body: null,
			onSuccess: () => {
				setSnackMessage('MFA reset was successful');
				setSuccessOpen(true);
				setShowResetMfaDialog(false);
				reloadUsers();
				setSearch('');
			},
			onError: (responseStatus) => {
				setSnackMessage(`The user hasn't set up their account`);
				setErrorOpen(true);
			},
		}
	);

	const { data: groups, loading: loadingGroups } = useFetch<Group[]>(
		'oidc/groups',
		{
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
			},
			body: null,

			onError: (responseStatus) => {
				setSnackMessage(`Failed to get groups. ${responseStatus}`);
				setErrorOpen(true);
			},
		}
	);

	useEffect(() => {
		if (users?.filter(filterUsersByDomain)) {
			if (filter === 'notInvited') {
				setFilteredUsers(
					users
						?.filter(filterUsersByDomain)
						.filter((u) => !u.invitationSendDate) || []
				);
			} else if (filter === 'invited') {
				setFilteredUsers(
					users
						?.filter(filterUsersByDomain)
						.filter((u) => !!u.invitationSendDate) || []
				);
			} else {
				setFilteredUsers(users.filter(filterUsersByDomain) || []);
			}

			if (search) {
				setFilteredUsers((f) =>
					f.filter(
						(u) =>
							u.firstname?.toLowerCase().includes(search.toLowerCase()) ||
							u.surname?.toLowerCase().includes(search.toLowerCase()) ||
							u.email?.toLowerCase().includes(search.toLowerCase()) ||
							u.department?.toLowerCase().includes(search.toLowerCase()) ||
							u.companyName?.toLowerCase().includes(search.toLowerCase())
					)
				);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [search, filter]);

	const handleSelectAll = (checked: boolean) => {
		if (checked) {
			const newSelecteds = filteredUsers?.map((n) => n.id);
			setSelected(newSelecteds || []);
			return;
		}
		setSelected([]);
	};

	const selectAllToggle = filteredUsers?.every((user) =>
		selected.includes(user.id)
	);

	const handleSelect = (id: string, checked: boolean) => {
		if (checked) {
			setSelected([...selected, id]);
			return;
		}
		setSelected(selected.filter((s) => s !== id));
	};

	const handleInvite = () => {
		//setIsSendingMail(true);
		toggleDrawer(false);
		const payload = selected
			.map((id) => {
				const user = users
					?.filter(filterUsersByDomain)
					?.find((u) => u.id === id);
				return {
					Id: user?.id,
					Email: user?.email,
					DisplayName:
						selectedService === 'MyControlRisks'
							? `${user?.firstname}`
							: `${user?.firstname} ${user?.surname}`,
					Service: selectedService,
				};
			})
			.filter((u) => !!u.Id && !!u.Email && !!u.DisplayName);

		fetchLazy(
			'user/send-set-password-email',
			{
				body: JSON.stringify(payload),
			},
			false
		);
	};

	const onCancel = () => {
		setShowInviteDialog(false);
		toggleDrawer(false);
		setSelected([]);
		setSelectedService('');
	};

	const handleEditUser = (user: User) => {
		setEditMode(true);
		setUserSelectedGroups(user.groups[0].split(','));

		setUserToEdit({
			...user,
			companyName:
				user.companyId === '001' ? 'Control Risks Ltd.' : user.companyName,
		});
	};

	const handleEditModalClose = () => {
		setEditMode(false);
		setUserToEdit(undefined);
	};

	const {
		register,
		control,
		getValues,
		handleSubmit,
		reset,
		setValue,
		formState: { errors },
	} = useForm({
		mode: 'all',
		resolver: yupResolver(schema),
		defaultValues: {
			...userToEdit,
			groups: userToEdit?.groups[0],
		},
	});

	const [userSelectedGroups, setUserSelectedGroups] = useState<any[]>([]);
	const [openDrawer, toggleDrawer] = useState<boolean>(false);
	const [selectedService, setSelectedService] = useState<string>("");

	useEffect(() => {
		reset({ ...userToEdit, groups: userToEdit?.groups[0] });
	}, [userToEdit, reset]);

	useEffect(() => {
		setValue('groups', userSelectedGroups.toString());
		// eslint-disable-next-line
	}, [userSelectedGroups]);

	const onSubmit = () => {
		let selectedGroups = '';

		userSelectedGroups?.forEach((el, index) => {
			const x = groups?.find((group) => group.id === el);
			if (x) {
				//selectedGroups.push(x.id);

				if (index !== 0) {
					selectedGroups = selectedGroups + ',' + x.id;
				} else {
					selectedGroups = selectedGroups + x.id;
				}
			}
		});

		fetchLazyEdit(
			'user/edit',
			{
				body: JSON.stringify({
					...getValues(),
					createdBy: accounts.at(0)?.name || '',
					groups: selectedGroups,
				}),
			},
			false
		);
	};

	const handleDelete = () => {
		const payload =
			typeof showDeleteDialog === 'string' ? [showDeleteDialog] : selected;

		fetchLazyDelete(
			'users/delete',
			{
				body: JSON.stringify(payload),
			},
			false
		);
	};

	const handleResetMfa = () => {
		fetchLazyResetMfa(
			`user/${showResetMfaDialog}/delete-2fa-methods`,
			{
				body: JSON.stringify({}),
			},
			false
		);
	};

	const sortTableData = (column: string) => {
		const isAscending =
			tableSortBy.column === column ? !tableSortBy.ascending : true;

		const sortedData = users?.filter(filterUsersByDomain)
			? [...filteredUsers].sort((a, b) => {
				const valueA = (a[column] || '').toString().toLowerCase();
				const valueB = (b[column] || '').toString().toLowerCase();

				if (valueA < valueB) return isAscending ? -1 : 1;
				if (valueA > valueB) return isAscending ? 1 : -1;
				return 0;
			})
			: [];

		setFilteredUsers(sortedData);
		setTableSortBy({ column, ascending: isAscending });
	};

	if (isSendingMail) {
		return <Loading text="Sending" position="absolute" />;
	}

	if (!users?.filter(filterUsersByDomain) && errorOpen) {
		return (
			<Snackbar
				open={errorOpen}
				autoHideDuration={6000}
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
				onClose={() => setErrorOpen(false)}
			>
				<Alert
					onClose={() => setErrorOpen(false)}
					severity="error"
					sx={{ width: '430px' }}
					variant="filled"
					icon={false}
				>
					Services are currently unavailable
				</Alert>
			</Snackbar>
		);
	}

	return (
		<Fragment>
			<UserInviteDrawer
				openDrawer={openDrawer}
				users={selected.map((id) => {
					const user = users
						?.filter(filterUsersByDomain)
						?.find((u) => u.id === id);
					return {
						Id: user?.id!,
						Email: user?.email!,
						DisplayName: `${user?.firstname} ${user?.surname}`!,
					};
				})}
				onClose={() => {
					toggleDrawer(false);
					setSelectedService('');
				}}
				onCancel={onCancel}
				onConfirm={() => handleInvite()}
				loading={loadingInvite}
				setSelectedUsers={setSelected}
				service={selectedService}
				setService={setSelectedService}
			/>
			<Snackbar
				open={successOpen}
				autoHideDuration={6000}
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
				onClose={() => setSuccessOpen(false)}
			>
				<Alert
					onClose={() => setSuccessOpen(false)}
					severity="success"
					sx={{ width: '430px' }}
					variant="filled"
					icon={false}
					color="success"
				>
					{snackMessage}
				</Alert>
			</Snackbar>
			<Snackbar
				open={errorOpen}
				autoHideDuration={3000}
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
				onClose={() => setErrorOpen(false)}
			>
				<Alert
					onClose={() => setErrorOpen(false)}
					severity="error"
					sx={{
						fontFamily: 'Nunito Sans, sans-serif',
						width: '430px',
						fontSize: '16px',
						lineHeight: '20px',
						fontWeight: '600',
					}}
					variant="filled"
					icon={false}
					color="error"
				>
					{snackMessage}
				</Alert>
			</Snackbar>
			<Dialog
				title={`Delete ${selected.length <= 1 ? 'user' : 'users'}`}
				message={`Are you sure you want to permanently delete ${selected.length <= 1 ? 'this user' : `${selected.length} users`
					}?`}
				onConfirm={() => handleDelete()}
				onClose={() => {
					setShowDeleteDialog(false);
					setSelected([]);
				}}
				onCancel={() => {
					setShowDeleteDialog(false);
					setSelected([]);
				}}
				open={!!showDeleteDialog}
				loading={loadingDelete}
			/>
			<Dialog
				open={!!showResetMfaDialog}
				title="Reset MFA?"
				message="Resetting the MFA will prompt the user to set up their account on the Microsoft Authenticator app. Are you sure you want to continue?"
				onClose={() => setShowResetMfaDialog(undefined)}
				onCancel={() => setShowResetMfaDialog(undefined)}
				onConfirm={() => handleResetMfa()}
				loading={loadingResetMfa}
			/>
			<UserInviteDialog
				title="Invite user"
				users={selected.map((id) => {
					const user = users
						?.filter(filterUsersByDomain)
						?.find((u) => u.id === id);
					return {
						Id: user?.id!,
						Email: user?.email!,
						DisplayName: `${user?.firstname} ${user?.surname}`!,
					};
				})}
				onConfirm={() => handleInvite()}
				onClose={() => {
					setShowInviteDialog(false);
					setSelectedService('');
				}}
				onCancel={() => onCancel()}
				open={showInviteDialog}
				loading={loadingInvite}
				setSelectedUser={setSelected}
				service={selectedService}
				setService={setSelectedService}
			/>
			<Banner
				title="Users"
				additional={
					<Chip
						label={`${selected?.length > 0 ? `${selected?.length} / ` : ''}${filteredUsers?.length
							}`}
						sx={{
							fontSize: '20px',
							lineHeight: '22px',
							fontWeight: '600',
							px: '8px',
							py: '8px',
							backgroundColor: '#226CA5',
							fontFamily: 'Inter, sans-serif',
						}}
						color="primary"
						variant="filled"
						aria-label="user count"
					/>
				}
				action={
					<Button
						variant="contained"
						startIcon={<AddCircleOutline />}
						size="large"
						onClick={() => navigate('/addUsers')}
						sx={{
							bgcolor: '#226CA5',
							color: '#FFF',
							textTransform: 'none',
							fontSize: '16px',
							fontWeiWeight: '600',
							borderRadius: '7px',
							transition: '0.3s box-shadow ease-in-out',
							'&:hover': {
								bgcolor: '#226CA5',
								color: '#FFF',
								boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
							},
						}}
					>
						Add a user
					</Button>
				}
			/>
			{!users?.filter(filterUsersByDomain) ||
				loadingUsers ||
				!groups ||
				loadingGroups ? (
				<Loading
					text="Loading users"
					boxProps={{
						minHeight: 'initial',
						flexGrow: 1,
						display: 'flex',
					}}
				/>
			) : (
				<Box
					component="section"
					sx={{
						py: '50px',
						px: {
							xs: '10px',
							md: '50px',
						},
						display: 'flex',
						flexDirection: 'column',
						flexGrow: 1,
						gap: '11px',
						backgroundImage: 'linear-gradient(#99B1B9 15%,#FFF,#FFF)',
					}}
				>
					<Box
						component="header"
						sx={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between',
							flexWrap: 'wrap',
						}}
					>
						<Box>
							<FormControl
								variant="standard"
								sx={{ width: '330px', maxWidth: '330px' }}
							>
								<Paper
									elevation={0}
									component="form"
									sx={{
										display: 'flex',
										alignItems: 'center',
										background: 'white',
										width: '100%',
										'&:focus-within': {
											borderRadius: 2,
											border: 0.5,
											borderColor: '#226CA5',
											elevation: 3,
										},
									}}
								>
									<InputBase
										sx={{
											p: '5px',
											width: '100%',
										}}
										onChange={({ target: { value } }) => {
											setSearch(value);
										}}
										placeholder="Search"
										startAdornment={
											<InputAdornment position="start">
												<Search />
											</InputAdornment>
										}
									/>
								</Paper>
							</FormControl>
						</Box>
						<Box
							sx={{
								display: 'flex',
								flexDirection: 'row',
								gap: '15px',
								flexWrap: 'wrap',
							}}
						>
							<FormControlLabel
								sx={{ margin: 0 }}
								control={
									<ToggleButtonGroup
										value={filter}
										exclusive
										onChange={(e, value) => setFilter(value)}
										sx={{
											borderRadius: '7px',
											border: '2px solid #226CA5',
											'& .MuiToggleButton-root': {
												borderRight: '2px solid #226CA5',
												backgroundColor: '#FAFAFA',
												color: '#242D35',
												width: '102px',
												textTransform: 'none',

												'&:hover': {
													backgroundColor: 'rgba(34, 108, 165, 0.25)',
													color: '#242D35',
												},

												'&.Mui-selected': {
													backgroundColor: '#226CA5',
													color: '#FFF',
												},
												'&.MuiToggleButtonGroup-grouped:not(:first-of-type)': {
													marginLeft: '0px',
													borderLeft: 'none',
													borderRight: 'none',
												},
											},
										}}
									>
										<ToggleButton value="notInvited">Not invited</ToggleButton>
										<ToggleButton value="invited">Invited</ToggleButton>
									</ToggleButtonGroup>
								}
								label=""
							/>
							<ButtonGroup
								component={Paper}
								elevation={6}
								variant="text"
								aria-label="text button group"
								sx={{
									backgroundColor: '#FAFAFA',
									borderRadius: '7px',
									textTransform: 'none',
									overflow: 'hidden',
									'& .MuiButton-root': {
										width: '102px',
										textTransform: 'none',
										color: '#224957',
										fontSize: '14px',
										fontWeight: '600',

										'&:hover, &:active': {
											backgroundColor: '#E4E5E5',
											color: '#768a91',
										},
									},
								}}
							>
								<Button
									onClick={() =>
										selected.length === 0
											? null
											: selected.length > 1
												? toggleDrawer(true)
												: setShowInviteDialog(true)
									}
									startIcon={<MailOutline color="success" />}
									sx={{}}
								>
									Invite
								</Button>
								<Button
									startIcon={<DeleteOutline color="error" />}
									onClick={() => setShowDeleteDialog(true)}
								>
									Delete
								</Button>
							</ButtonGroup>
						</Box>
					</Box>
					<Box>
						<DataTable
							headings={headings}
							handleSelectAll={handleSelectAll}
							selectAll={true}
							isAllSelected={selectAllToggle}
							sortBy={tableSortBy}
							sortedTable={sortTableData}
							rows={filteredUsers.map((user) => (
								<TableRow
									sx={{
										'& .MuiTableCell-root': {
											height: '45px',
											padding: '0',
										},
										'&:last-child td, &:last-child th': {
											border: 0,
										},
									}}
									key={user.id}
								>
									<TableCell
										sx={{
											overflow: 'hidden',
											textOverflow: 'ellipsis',
											whiteSpace: 'nowrap',
										}}
									>
										<FormControlLabel
											sx={{ margin: 0 }}
											control={
												<Checkbox
													onChange={(e, checked) =>
														handleSelect(user.id, checked)
													}
													checked={selected.includes(user.id)}
													sx={{
														color: '#226CA5',
														'&:hover': {
															backgroundColor: 'transparent',
														},
														'&.Mui-checked': {
															color: '#226CA5',
															'&:hover': {
																backgroundColor: 'transparent',
															},
														},
													}}
												/>
											}
											label=""
										/>
									</TableCell>
									<TableCell
										align="left"
										sx={{
											overflow: 'hidden',
											textOverflow: 'ellipsis',
											whiteSpace: 'nowrap',
										}}
									>
										{user?.firstname}
									</TableCell>
									<TableCell
										align="left"
										sx={{
											overflow: 'hidden',
											textOverflow: 'ellipsis',
											whiteSpace: 'nowrap',
										}}
									>
										{user?.surname}
									</TableCell>
									<TableCell
										align="left"
										sx={{
											overflow: 'hidden',
											textOverflow: 'ellipsis',
											whiteSpace: 'nowrap',
										}}
									>
										{user?.email}
									</TableCell>
									<TableCell
										align="left"
										sx={{
											overflow: 'hidden',
											textOverflow: 'ellipsis',
											whiteSpace: 'nowrap',
										}}
									>
										{user?.department}
									</TableCell>
									<TableCell
										align="left"
										sx={{
											overflow: 'hidden',
											textOverflow: 'ellipsis',
											whiteSpace: 'nowrap',
										}}
									>
										{user?.companyId}
									</TableCell>
									<TableCell
										align="left"
										sx={{
											overflow: 'hidden',
											textOverflow: 'ellipsis',
											whiteSpace: 'nowrap',
										}}
									>
										{user.companyName}
									</TableCell>
									<TableCell
										align="left"
										sx={{
											overflow: 'hidden',
											textOverflow: 'ellipsis',
											whiteSpace: 'nowrap',
										}}
									>
										{user?.invitationSendDate ? (
											user.invitationStatus === 'error' ? (
												<Box display="flex" justifyContent="center">
													<HighlightOffIcon color="primary" />
												</Box>
											) : (
												moment(user?.invitationSendDate).format('DD/MM/YYYY')
											)
										) : null}
									</TableCell>
									<TableCell>
										{user && user.groups && user.groups[0] ? (
											<>
												<ADB2CGroupChips
													groups={groups?.filter((group) =>
														user?.groups[0]
															.split(',')
															?.find((usergroup) => group.id === usergroup)
													)}
													editable={false}
												/>
											</>
										) : (
											<></>
										)}
									</TableCell>
									<TableCell
										align="left"
										sx={{
											overflow: 'hidden',
											textOverflow: 'ellipsis',
											whiteSpace: 'nowrap',
										}}
									>
										{user?.modifiedBy}
									</TableCell>
									<TableCell
										align="left"
										sx={{
											overflow: 'hidden',
											textOverflow: 'ellipsis',
											whiteSpace: 'nowrap',
										}}
									>
										{user?.createdBy}
									</TableCell>
									<TableCell align="center">
										<IconButton
											color="primary"
											onClick={(e) => {
												setShowPopover(user.id);
												setPopoverAnchor(e.currentTarget);
											}}
											aria-describedby={`popover-${user.id}`}
										>
											<MoreVert />
										</IconButton>
										<Popover
											id={`popover-${user.id}`}
											open={showPopover === user.id}
											onClose={() => setShowPopover(undefined)}
											anchorEl={popoverAnchor}
											anchorOrigin={{
												vertical: 'bottom',
												horizontal: 'right',
											}}
										>
											<ButtonGroup
												orientation="vertical"
												color="primary"
												variant="text"
												sx={{
													backgroundColor: '#FFFFFF',
													borderRadius: '7px',
													textTransform: 'none',
													overflow: 'hidden',
													border: 'none',
													boxShadow: '0px 4px 4px 0px #00000040',
													'& .MuiButton-root': {
														width: '135px',
														textTransform: 'none',
														color: '#224957',
														fontSize: '14px',
														fontWeight: '600',
														py: '8px',
														px: '14px',
														border: 'none !important',
														justifyContent: 'flex-start',
														gap: '0.5rem',
														'&:hover': {
															color: '#FFF',
														},
													},
												}}
											>
												<Button
													onClick={() => {
														setShowPopover(undefined);
														handleEditUser(user);
													}}
												>
													<Edit color="primary" />
													Edit
												</Button>
												<Button
													onClick={() => {
														if (user?.invitationSendDate) {
															setShowResetMfaDialog(user.id);
															setShowPopover(undefined);
														} else {
															setSnackMessage(
																"The user hasn't set up their account"
															);
															setErrorOpen(true);
														}
													}}
												>
													<Autorenew
														color="primary"
														sx={{
															transform: 'rotate(100deg)',
														}}
													/>
													Reset MFA
												</Button>
												<Box sx={{ paddingX: '0.75rem' }}>
													<Box
														sx={{
															width: '100%',
															height: '1px',
															backgroundColor: '#7E8081',
														}}
													/>
												</Box>
												<Button
													onClick={() => {
														setShowDeleteDialog(user.id);
														setShowPopover(undefined);
													}}
												>
													<DeleteOutline color="error" />
													Delete
												</Button>
											</ButtonGroup>
										</Popover>
									</TableCell>
								</TableRow>
							))}
						/>
					</Box>
					<Modal
						open={editMode}
						onClose={handleEditModalClose}
						sx={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						<Box
							sx={{
								width: '80dvh',
								padding: '2rem 3rem',

								display: 'flex',
								flexDirection: 'column',

								borderTopStyle: 'solid',
								borderTopWidth: '8px',
								borderTopColor: '#003D50',
								borderRadius: '5px',

								backgroundColor: 'white',
							}}
						>
							<Box display={'flex'} justifyContent={'space-between'}>
								<Typography variant="h5" color={'primary'}>
									Edit user
								</Typography>
								<IconButton onClick={handleEditModalClose} color="primary">
									<Close />
								</IconButton>
							</Box>
							<Divider sx={{ marginBottom: '1rem' }} />
							<form onSubmit={handleSubmit(onSubmit)}>
								<Grid container spacing={3}>
									<Grid item xs={6}>
										<Controller
											name="firstname"
											control={control}
											render={({
												field: { onChange, value },
												fieldState: { error, invalid },
											}) => (
												<Input
													formController={{ ...register('firstname') }}
													type="text"
													required
													label="First name"
													placeholder="Enter First name"
													id="firstname"
													fullWidth
													error={!!errors?.firstname}
													helperText={invalid ? error?.message : null}
												/>
											)}
										/>
									</Grid>
									<Grid item xs={6}>
										<Controller
											name="surname"
											control={control}
											render={({
												field: { onChange, value },
												fieldState: { error, invalid },
											}) => (
												<Input
													formController={{ ...register('surname') }}
													type="text"
													required
													label="Last name"
													placeholder="Enter Last name"
													id="surname"
													fullWidth
													error={!!errors?.surname}
													helperText={invalid ? error?.message : null}
												/>
											)}
										/>
									</Grid>
									<Grid item xs={12}>
										<Controller
											name="email"
											control={control}
											render={({
												field: { onChange, value },
												fieldState: { error, invalid },
											}) => (
												<Input
													formController={{ ...register('email') }}
													type="text"
													required
													disabled
													label="Email"
													placeholder="Enter Email"
													id="email"
													fullWidth
													error={!!errors?.email}
													helperText={invalid ? error?.message : null}
												/>
											)}
										/>
									</Grid>
									<Grid item xs={12}>
										<Controller
											name="department"
											control={control}
											render={({
												field: { onChange, value },
												fieldState: { error, invalid },
											}) => (
												<Input
													formController={{ ...register('department') }}
													type="text"
													label="Location"
													placeholder="Enter Location"
													id="department"
													fullWidth
													required
													error={!!errors?.department}
													helperText={invalid ? error?.message : null}
												/>
											)}
										/>
									</Grid>
									<Grid item xs={6}>
										<Controller
											name="companyId"
											control={control}
											render={({
												field: { onChange, value },
												fieldState: { error, invalid },
											}) => (
												<Input
													formController={{ ...register('companyId') }}
													type="text"
													required
													disabled={
														userToEdit?.companyId === '001' ? true : false
													}
													label="CRM account number"
													placeholder="Enter CRM account number"
													id="companyId"
													readOnly={true}
													fullWidth
													error={!!errors?.companyId}
													helperText={invalid ? error?.message : null}
												/>
											)}
										/>
									</Grid>
									<Grid item xs={6}>
										<Controller
											name="companyName"
											control={control}
											render={({
												field: { onChange, value },
												fieldState: { error, invalid },
											}) => (
												<Input
													formController={{ ...register('companyName') }}
													type="text"
													required={true}
													disabled={
														userToEdit?.companyId === '001' ? true : false
													}
													label="Company name"
													placeholder="Enter Company name"
													id="companyName"
													readOnly={true}
													fullWidth
													error={!!errors?.companyName}
													helperText={invalid ? error?.message : null}
												/>
											)}
										/>
									</Grid>
									<Grid item xs={12}>
										<Grid container alignContent="flex-end" spacing={1}>
											<Grid item xs={6}>
												<Controller
													name="groups"
													control={control}
													render={({
														field: { onChange, value },
														fieldState: { error, invalid },
													}) => (
														<Input
															formController={{ ...register('groups') }}
															required
															label="Service"
															placeholder="Enter service"
															id="groups"
															fullWidth
															defaultValue=""
															error={!!errors?.groups}
															selectValues={groups
																.filter(
																	(group) =>
																		group.displayName.toLowerCase() ===
																		'client portal' ||
																		group.displayName.toLowerCase() ===
																		'client dashboard' ||
																		group.displayName.toLowerCase() ===
																		'mycontrolrisks'
																)
																.sort((a, b) =>
																	a.displayName.localeCompare(b.displayName)
																)}
															onChange={(e): any => {
																setUserSelectedGroups((oldArray) => {
																	if (
																		oldArray.find((x) => x === e.target.value)
																	) {
																		return [...oldArray];
																	} else {
																		return [...oldArray, e.target.value];
																	}
																});
															}}
															helperText={invalid ? error?.message : null}
														/>
													)}
												/>
											</Grid>
											<Grid item xs={5} display="flex" alignItems="flex-end">
												<Box sx={{ marginBottom: '5px' }}>
													<ADB2CGroupChips
														groups={groups.filter((g) =>
															userSelectedGroups.find((u) => u === g.id)
														)}
														editable={true}
														setUserSelectedGroups={setUserSelectedGroups}
														userSelectedGroups={userSelectedGroups}
													/>
												</Box>
											</Grid>
										</Grid>
									</Grid>

									<Grid item xs={6} display={'flex'} alignItems={'end'}>
										<Box display={'flex'} marginBottom={1} gap={1}></Box>
									</Grid>
								</Grid>
								<Box
									sx={{
										display: 'flex',
										justifyContent: 'center',
										marginTop: '2rem',
									}}
								>
									<Button
										style={{ ...CustomCanceltButton, textTransform: 'none' }}
										variant="text"
										onClick={handleEditModalClose}
									>
										Cancel
									</Button>
									<Button
										style={{ ...CustomSubmitButton, textTransform: 'none' }}
										type="submit"
										onClick={onSubmit}
									>
										{loadingEdit ? 'Save' : 'Save'}
									</Button>
								</Box>
							</form>
						</Box>
					</Modal>
				</Box>
			)}
		</Fragment>
	);
};

export default Home;
