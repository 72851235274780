import {
	TooltipProps,
	styled,
	tooltipClasses,
	Tooltip as MuiTooltip,
} from '@mui/material';


const Tooltip = styled(({ className, ...props }: TooltipProps) => (
	<MuiTooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.common.white,
		boxShadow: theme.shadows[1],
		fontSize: '0.875rem',
		fontWeight: 'normal',
		textAlign: 'center',
		padding: '0.5rem 0.75rem',
	},
	[`& .${tooltipClasses.arrow}`]: {
		color: theme.palette.primary.main,
	},
}));


export {Tooltip}
