import { FC } from 'react';
import {
	Dialog as MuiDialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Box,
	useTheme,
	Button,
	CircularProgress,
} from '@mui/material';

type Props = {
	title: string;
	message: string;
	open: boolean;
	loading: boolean;
	onClose: () => void;
	onConfirm?: () => void;
	onCancel?: () => void;
};

const Dialog: FC<Props> = (props) => {
	const { title, message, open, onClose, onConfirm, onCancel, loading } = props;
	const theme = useTheme();
	return (
		<MuiDialog
			fullWidth
			sx={{
				'& .MuiDialog-paper': {
					maxWidth: '709px',
				},
			}}
			open={open}
			onClose={onClose}
			aria-labelledby={`alert-dialog-${title}`}
		>
			<Box
				sx={{
					backgroundColor: theme.palette.common.white,
					borderTopStyle: 'solid',
					borderTopWidth: '10px',
					borderTopColor: '#003D50',
					borderRadius: '5px',
					px: '30px',
				}}
			>
				<DialogTitle
					id={`alert-dialog-${title}`}
					sx={{
						paddingTop: '25px',
						paddingBottom: '13px',
						px: '0',
						margin: 0,
						fontWeight: '500',
						fontSize: '32px',
						lineHeight: '38.73px',
					}}
				>
					{title}
				</DialogTitle>
				<Box sx={{ height: '1px', backgroundColor: '#CACDCD' }}></Box>
				<DialogContent
					sx={{
						px: '14px',
						py: '30px',
						border: 'none',
						fontWeight: '400',
						fontSize: '16px',
						lineHeight: '21.79px',
						color: '#224957',
					}}
				>
					{message}
				</DialogContent>
				<DialogActions
					sx={{
						display: 'flex',
						justifyContent: 'center',
						gap: '25px',
						alignItems: 'center',
						margin: '0',
						padding: '0 !important',
						'& .MuiButton-root': {
							textTransform: 'none',
							fontWeight: '600',
							fontSize: '14px',
							lineHeight: '19.07px',
							letterSpacing: '-1.5%',
							padding: '10px 50px',
							marginBottom: '27px',
							marginTop: '19px',
							borderRadius: '7px',
						},
					}}
				>
					<Button
						variant="outlined"
						onClick={onCancel}
						sx={{
							color: '#003D50',
							border: '1px solid #003D50',
							'&:hover': {
								backgroundColor: '#003D50',
								color: theme.palette.common.white,
							},
						}}
					>
						Cancel
					</Button>
					<Button
						variant="contained"
						onClick={onConfirm}
						color="primary"
						disabled={loading}
						sx={{
							backgroundColor: '#003D50',
							'&:disabled': {
								backgroundColor: theme.palette.primary.main,
								color: theme.palette.common.white,
								filter: 'opacity(0.7)',
							},
						}}
					>
						{loading ? (
							<CircularProgress
								size={24}
								sx={{
									color: theme.palette.common.white,
									mr: 1,
								}}
							/>
						) : null}
						Confirm
					</Button>
				</DialogActions>
			</Box>
		</MuiDialog>
	);
};

export default Dialog;
