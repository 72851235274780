import { FC, useEffect, useState } from 'react';
import {
	Drawer,
	List,
	useTheme,
	Typography,
	FormControl,
	RadioGroup,
	Radio,
	FormControlLabel,
	Stack,
	Button,
	CircularProgress,
} from '@mui/material';
import { ReactComponent as VectorIcon } from '../../../assets/Vector.svg';
import { TextWithTooltip } from '../textWithTooltip';

type UserTrimmed = {
	DisplayName: string;
	Id: string;
	Email: string;
};

type Props = {
	openDrawer: boolean;
	users?: UserTrimmed[];
	setSelectedUsers?: any;
	loading: boolean;
	onClose: () => void;
	onCancel: () => void;
	onConfirm?: () => void;
	service?: string;
	setService?: any;
};

const UserInviteDrawer: FC<Props> = (props) => {
	const {
		openDrawer,
		users,
		setSelectedUsers,
		loading,
		onClose,
		onCancel,
		onConfirm,
		service,
		setService,
	} = props;
	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setService((event.target as HTMLInputElement).value);
	};

	const [radioError, setRadioError] = useState<boolean>(false);

	useEffect(() => {
		// eslint-disable-next-line react-hooks/exhaustive-deps
		setRadioError(false);
	}, [service, users]);

	const throwRadioError = () => {
		setRadioError(true);
	};

	const theme = useTheme();

	const DrawerList = (
		<Stack
			sx={{ width: 464, height: '100%', backgroundColor: '#FFFFFF' }}
			role="presentation"
		>
			<Stack
				sx={{
					px: 3,
					py: '8px',
					borderBottom: '1px solid #DEDEDE',
					height: '40px',
				}}
				direction="row"
				alignItems="center"
				justifyContent="space-between"
			>
				<Typography fontSize={'20px'} fontWeight={600} color={'#242D35'}>
					Invite users
				</Typography>

				<Stack
					alignItems="center"
					justifyContent="center"
					onClick={onCancel}
					sx={{
						'&:hover': {
							backgroundColor: '#E6EBED',
						},
						cursor: 'pointer',
						height: '40px',
						width: '40px',
					}}
				>
					<VectorIcon />
				</Stack>
			</Stack>

			<Typography
				sx={{ px: 3, paddingTop: 3 }}
				fontSize={'14px'}
				fontWeight={400}
				color={'#595958'}
			>
				Select a service
			</Typography>

			<FormControl sx={{ paddingLeft: 2 }}>
				<RadioGroup
					row
					aria-labelledby="demo-row-radio-buttons-group-label"
					name="row-radio-buttons-group"
					value={service}
					onChange={handleChange}
				>
					<FormControlLabel
						value="MyControlRisks"
						sx={{
							'&:hover': {
								bgcolor: '#EEEEEE',
							},
							px: 1,
						}}
						control={
							<Radio
								disableRipple
								sx={{ color: radioError ? 'red' : '#242D35' }}
							/>
						}
						label={
							<Typography
								fontSize={'16px'}
								fontWeight={400}
								color={radioError ? 'red' : '#242D35'}
							>
								MyControlRisks
							</Typography>
						}
					/>
					<FormControlLabel
						value="Client Portal"
						sx={{
							'&:hover': {
								bgcolor: '#EEEEEE',
							},
							px: 1,
						}}
						control={
							<Radio
								disableRipple
								sx={{ color: radioError ? 'red' : '#242D35' }}
							/>
						}
						label={
							<Typography
								fontSize={'16px'}
								fontWeight={400}
								color={radioError ? 'red' : '#242D35'}
							>
								Client Portal
							</Typography>
						}
					/>
				</RadioGroup>
			</FormControl>

			<Stack sx={{ height: '24px' }}></Stack>

			<List
				sx={{
					overflowY: 'scroll',
					overflowX: 'none',
					height: '100%',
				}}
			>
				<Stack>
					{users
						?.sort((a, b) => a.DisplayName.localeCompare(b.DisplayName))
						.map((user, index) => (
							<Stack
								alignItems={'center'}
								justifyContent={'space-between'}
								direction="row"
								sx={{ px: 3, py: 2 }}
							>
								<Stack>
									<TextWithTooltip
										text={user.DisplayName ? user.DisplayName : ''}
										limit={40}
										textStyle={{
											color: '#242D35',
											fontWeight: 400,
											fontSize: '16px',
										}}
										tooltipStyle={{ zIndex: '3000' }}
									/>
									<TextWithTooltip
										text={'(' + user.Email + ')'}
										limit={40}
										textStyle={{
											color: '#8B8B8A',
											fontWeight: 400,
											fontSize: '16px',
										}}
										tooltipStyle={{ zIndex: '3000' }}
									/>
								</Stack>
								<Stack
									alignItems="center"
									justifyContent="center"
									sx={{
										height: '32px',
										width: '32px',
										'&:hover': {
											backgroundColor: '#E6EBED',
										},
									}}
								>
									<VectorIcon
										style={{
											cursor: 'pointer',
										}}
										onClick={() =>
											setSelectedUsers((oldArray) =>
												oldArray.filter((x) => x !== user.Id)
											)
										}
									/>
								</Stack>
							</Stack>
						))}
				</Stack>
			</List>

			<Stack
				direction={'row'}
				sx={{
					height: '7%',
					borderTop: '1px solid #DEDEDE',
					px: 2,
					py: 2,
				}}
				alignItems={'flex-end'}
				justifyContent={'flex-end'}
				spacing={3}
			>
				<Button
					variant="text"
					onClick={onCancel}
					sx={{
						height: '40px',
						width: '50px',
						px: 1,
						py: 1,
						'&:hover': {
							backgroundColor: 'transparent',
							color: theme.palette.common.white,
						},
					}}
				>
					<Typography
						color={'#053747'}
						sx={{ textTransform: 'none', borderBottom: '1px solid #053747' }}
						fontWeight={600}
						fontSize={16}
					>
						Cancel
					</Typography>
				</Button>
				<Button
					variant="contained"
					onClick={service !== '' ? onConfirm : throwRadioError}
					color="primary"
					disabled={loading}
					sx={{
						height: '40px',
						width: '145px',
						px: 1,
						py: 1,
						'&:disabled': {
							backgroundColor: theme.palette.primary.main,
							color: theme.palette.common.white,
							filter: 'opacity(0.7)',
						},
					}}
				>
					{loading ? (
						<CircularProgress
							size={24}
							sx={{
								color: theme.palette.common.white,
								mr: 1,
							}}
						/>
					) : null}
					<Typography
						color={'#FFFFFF'}
						sx={{ textTransform: 'none' }}
						fontWeight={600}
						fontSize={16}
					>
						Invite {users?.length} users
					</Typography>
				</Button>
			</Stack>
		</Stack>
	);

	return (
		<>
			<Drawer
				open={openDrawer}
				anchor={'right'}
				onClose={onClose}
				sx={{ zIndex: '2000' }}
			>
				{DrawerList}
			</Drawer>
		</>
	);
};

export default UserInviteDrawer;
