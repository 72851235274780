import React from 'react';
import { Chip, Stack, Typography } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

type Props = {
	groups?: any[];
	editable?: boolean;
	setUserSelectedGroups?: any;
	userSelectedGroups?: any[];
	renderWithString?: boolean;
	nameString?: string;
};

const ADB2CGroupChips: React.FC<Props> = ({
	groups,
	editable,
	setUserSelectedGroups,
	userSelectedGroups,
	renderWithString,
	nameString,
}) => {
	const handleDelete = (group) => {
		setUserSelectedGroups(
			userSelectedGroups?.filter((item) => item !== group.id)
		);
	};

	function renderNameChip(group) {
		let bgColor = 'gray';
		let text = 'n/a';

		//Client Portal service
		if (
			group?.displayName.toLowerCase() === 'client portal' ||
			group?.displayName.toLowerCase() === 'client dashboard'
		) {
			bgColor = '#EBAD66';
			text = 'CP';
		} else if (group?.displayName.toLowerCase() === 'mycontrolrisks') {
			//myCR service
			bgColor = '#56988D';
			text = 'MyCR';
		}

		let chipLabel = (
			<Typography sx={{ fontWeight: 600, fontSize: '14px', color: '##242D35' }}>
				{text}
			</Typography>
		);
		return (
			<>
				{editable ? (
					<>
						<Chip
							sx={{
								marginTop: '5px',
								width: '90px',
								height: '24px',
								backgroundColor: bgColor,
							}}
							label={chipLabel}
							onDelete={() => handleDelete(group)}
							deleteIcon={<ClearIcon style={{ color: '#242D35' }} />}
						></Chip>
					</>
				) : (
					<>
						<Chip
							sx={{
								marginTop: '5px',
								width: '90px',
								height: '24px',
								backgroundColor: bgColor,
							}}
							label={chipLabel}
						></Chip>
					</>
				)}
			</>
		);
	}

	function renderStringChip(nameString) {
		const nameStringArray = nameString.split(',');
		let returnStack: any[] = [];

		nameStringArray.forEach((element) => {
			let bgColor = 'gray';
			let text = 'n/a';

			//myCR service
			if (
				element.toLowerCase().trim() === 'mycontrolrisks' ||
				element.toLowerCase().trim() === 'mycr'
			) {
				bgColor = '#56988D';
				text = 'MyCR';
			} else if (
				//c portal service
				element.toLowerCase().trim() === 'client portal' ||
				element.toLowerCase().trim() === 'client dashboard'
			) {
				bgColor = '#EBAD66';
				text = 'CP';
			}

			let chipLabel = (
				<Typography
					sx={{ fontWeight: 600, fontSize: '14px', color: '##242D35' }}
				>
					{text}
				</Typography>
			);
			returnStack.push(
				<>
					<Chip
						sx={{
							marginTop: '5px',
							width: '90px',
							height: '24px',
							backgroundColor: bgColor,
						}}
						label={chipLabel}
					></Chip>
				</>
			);
		});

		return <Stack direction="row">{returnStack}</Stack>;
	}

	if (renderWithString) {
		return <>{renderStringChip(nameString)}</>;
	} else {
		return (
			<Stack direction="row">
				{groups?.map((g) => (
					<>{renderNameChip(g)}</>
				))}
			</Stack>
		);
	}
};
export default ADB2CGroupChips;
