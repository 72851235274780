import { FC, useEffect, useState } from 'react';
import {
	Dialog as MuiDialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	useTheme,
	Button,
	CircularProgress,
	Stack,
	FormControl,
	RadioGroup,
	FormControlLabel,
	Radio,
    Typography,
} from '@mui/material';
import { TextWithTooltip } from '../textWithTooltip';

type UserTrimmed = {
	DisplayName: string;
	Id: string;
	Email: string;
};

type Props = {
	title: string;
	open: boolean;
	loading: boolean;
	onClose: () => void;
	onConfirm?: () => void;
	onCancel?: () => void;
	users?: UserTrimmed[];
	setSelectedUser?: any;
	service?: string;
	setService?: any;
};

const UserInviteDialog: FC<Props> = (props) => {
	const {
		title,
		open,
		onClose,
		onConfirm,
		onCancel,
		loading,
		users,
		service,
		setService,
	} = props;

	const [radioError, setRadioError] = useState<boolean>(false);
	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setService((event.target as HTMLInputElement).value);
	};
	useEffect(() => {
		// eslint-disable-next-line react-hooks/exhaustive-deps
		setRadioError(false);
	}, [service, users]);

	const throwRadioError = () => {
		setRadioError(true);
	};

	const theme = useTheme();
	if (users && users.length > 0) {
		return (
			<MuiDialog
				fullWidth
				open={open}
				onClose={onClose}
				aria-labelledby={`alert-dialog-${title}`}
				sx={{
					'& .MuiDialog-container': {
						'& .MuiPaper-root': {
							width: '100%',
							maxWidth: '704px', // Set your width here
						},
					},
				}}
			>
				<Stack
					sx={{
						backgroundColor: 'white',
						borderTop: '10px solid #003D50',
					}}
				>
					<DialogTitle
						id={`alert-dialog-${title}`}
						sx={{
							pt: '37px',
							pb: '25px',
							px: '30px',
							m: 0,
							fontWeight: '600',
							fontSize: '32px',
							lineHeight: '14px',
							background: 'white',
						}}
					>
						{title}
					</DialogTitle>
					<DialogContent
						sx={{
							px: '30px',
							py: '30px',
							borderBottom: 'none',
							background: 'white',
						}}
					>
						<Stack
							direction="row"
							alignItems="center"
							justifyContent="space-between"
							sx={{
								borderTop: '1px solid #CACDCD',
								pt: '30px',
							}}
						>
							<Stack>
								<TextWithTooltip
									text={users[0].DisplayName ? users[0].DisplayName : ''}
									limit={30}
									textStyle={{
										color: '#242D35',
										fontWeight: 400,
										fontSize: '16px',
									}}
								/>
								<TextWithTooltip
									text={'(' + users[0].Email + ')'}
									limit={30}
									textStyle={{
										color: '#8B8B8A',
										fontWeight: 400,
										fontSize: '16px',
									}}
								/>
							</Stack>
							<Stack direction="row">
								<FormControl>
									<RadioGroup
										row
										aria-labelledby="demo-row-radio-buttons-group-label"
										name="row-radio-buttons-group"
										value={service}
										onChange={handleChange}
									>
										<FormControlLabel
											value="MyControlRisks"
											control={
												<Radio
													disableRipple
													sx={{ color: radioError ? 'red' : '#242D35' }}
												/>
											}
											label={
												<Typography
													fontSize={'16px'}
													fontWeight={400}
													color={radioError ? 'red' : '#242D35'}
												>
													MyControlRisks
												</Typography>
											}
										/>
										<FormControlLabel
											value="Client Portal"
											control={
												<Radio
													disableRipple
													sx={{ color: radioError ? 'red' : '#242D35' }}
												/>
											}
											label={
												<Typography
													fontSize={'16px'}
													fontWeight={400}
													color={radioError ? 'red' : '#242D35'}
												>
													Client Portal
												</Typography>
											}
										/>
									</RadioGroup>
								</FormControl>
							</Stack>
						</Stack>
					</DialogContent>
					<DialogActions
						sx={{
							display: 'flex',
							justifyContent: 'flex-end',
							gap: '15px',
							alignItems: 'center',
							margin: '0',
							background: 'white',
							px: '30px',
							'& .MuiButton-root': {
								textTransform: 'none',
								fontWeight: '600',
								fontSize: '14px',
								lineHeight: '20px',
								padding: '10px 50px',
								marginBottom: '30px',
								marginTop: '10px',
								borderRadius: '7px',
							},
						}}
					>
						<Button
							variant="text"
							onClick={onCancel}
							sx={{
								'&:hover': {
									backgroundColor: 'white',
								},
							}}
						>
							<Typography fontWeight={600} fontSize={14} color={'#6A6A69'}>
								Cancel
							</Typography>
						</Button>
						<Button
							variant="contained"
							onClick={service !== '' ? onConfirm : throwRadioError}
							color="primary"
							disabled={loading}
							sx={{
								'&:disabled': {
									backgroundColor: theme.palette.primary.main,
									color: theme.palette.common.white,
									filter: 'opacity(0.7)',
								},
							}}
						>
							{loading ? (
								<CircularProgress
									size={24}
									sx={{
										color: theme.palette.common.white,
										mr: 1,
									}}
								/>
							) : null}
							Invite
						</Button>
					</DialogActions>
				</Stack>
			</MuiDialog>
		);
	} else {
		return null;
	}
};

export default UserInviteDialog;
