import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
//import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { ConfigProvider } from './config';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme/theme';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');
const root = createRoot(rootElement!);

root.render(
	<BrowserRouter basename={baseUrl || ''}>
		<ConfigProvider>
			<ThemeProvider theme={theme()}>
				<App />
			</ThemeProvider>
		</ConfigProvider>
	</BrowserRouter>
);

//serviceWorkerRegistration.unregister();
reportWebVitals();
