import React from 'react';
import { Dropzone, FileItem } from '@dropzone-ui/react';
// using .jsx because Dropzone library does not support children and style as parameters.

const FileDropZone = ({ onChange, onDelete, files, label }) => {
    return <Dropzone
        style={{
            marginTop: 15,
            minHeight: '150px',
            maxHeight: '190px',
            backgroundColor: "#00847533",
            border: '1px solid #008475',
            whiteSpace: 'pre-line',
            overflow: 'hidden',
            fontSize: '80%',
        }}
        behaviour="replace"
        value={files}
        onChange={onChange}
        accept="application/pdf, .xlsx, .xls, .csv,.xlsb, .xlsm"
        maxFiles={1}
        view="list"
        footer={false}
        label={label}
    >
        {files?.length > 0 && files.map((file) => (
            <FileItem
                {...file}
                preview
                onDelete={onDelete}
                key={file.id}
                info
            />
        ))}
    </Dropzone>;
}

export default FileDropZone;