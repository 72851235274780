import { lazy } from 'react';
import { Home } from './pages';

const AddUsers = lazy(() => import('./pages/addUsers'));

export const routes = [
	{
		path: '/',
		index: true,
		element: <Home />,
	},
	{
		path: '/addUsers',
		element: <AddUsers />,
	},
];
