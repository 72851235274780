import { Box, Button, Typography } from '@mui/material';
import React, { FC, ReactNode, memo } from 'react';
import DownloadIcon from '@mui/icons-material/Download';

type Props = {
	title: string;
	action?: ReactNode;
	additional?: ReactNode;
	showDownloadButton?: boolean;
};

const Banner: FC<Props> = (props?) => {

	const { title, action, additional, showDownloadButton } = props;

	function downloadTemplate() {
		const link = document.createElement("a");
		link.download = `Bulk creation template.xlsx`;
		link.href = "Bulk creation template.xlsx";
		link.click();
	}

	return (
		<Box
			component="section"
			sx={{
				py: '20px',
				px: {
					xs: '10px',
					md: '50px',
				},
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'space-between',
				alignItems: 'center',
			}}
		>
			<Box
				component="article"
				sx={{
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					gap: '16px',
				}}
			>
				<Typography
					variant="h1"
					sx={{
						fontSize: '28px',
						lineHeight: '30px',
						letterSpacing: '-0.544px',
						fontWeight: '400',
					}}
				>
					{title}
				</Typography>
				{additional}
			</Box>
			{typeof (showDownloadButton) === 'undefined' ? null : <Button onClick={() => downloadTemplate()} sx={{ textTransform: 'none', backgroundColor: '#226CA5', color: 'white', width: '225px', height: '35px' }}><DownloadIcon color='primary' sx={{width:'20px',height:'20px',color:'white',margin:'5px'}} />Download Template</Button>}
			{action}
		</Box>
	);
};

export default memo(Banner);