import { useState, useContext, memo } from 'react';
import { styled } from '@mui/material/styles';
import {
	useTheme,
	ClickAwayListener,
	Avatar,
	AvatarProps,
	AppBar,
	Box,
	Toolbar,
	IconButton,
	Dialog,
} from '@mui/material';
import { useMsal } from '@azure/msal-react';
import { decodeToken } from 'react-jwt';
import Button from '../components/atoms/button/button';
import logo from '../assets/images/Logo.png';
import { ReactComponent as DownArrow } from '../assets/arrows/DownAlt.svg';
import { ReactComponent as CloseIcon } from '../assets/closeX.svg';
import { ReactComponent as LogOutIcon } from '../assets/logOutIcon.svg';
import { Config } from '../config';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
	'& .MuiDialogContent-root': {
		padding: theme.spacing(2),
	},
	'& .MuiDialogActions-root': {
		padding: theme.spacing(1),
	},
}));

function stringAvatar(name: string): AvatarProps {
	let initials = '';
	if (name !== undefined) {
		const parts = name.split(' ');
		initials =
			parts.length > 1
				? `${parts[0].substring(0, 1)}${parts[1].substring(0, 1)}`
				: `${parts[0].substring(0, 1)}`;
	}
	return {
		sx: {
			bgcolor: '#d9f1f0',
			border: '2px solid #059dc1',
			width: '35px',
			height: '35px',
			fontSize: '14px',
			fontWeight: '600',
			color: '#7E8081',
		},
		children: initials.toUpperCase(),
	};
}

const Header = () => {
	const { instance, accounts } = useMsal();
	const { token } = useContext(Config);
	const decoded = token && decodeToken<any>(token);
	const [showPopUp, setShowPopUp] = useState(false);
	const [openModal, setOpenModal] = useState(false);
	const theme = useTheme();

	function signOutClickHandler(instance) {
		const logoutRequest = {
			account: accounts[0],
			postLogoutRedirectUri: '/',
		};
		instance.logoutRedirect(logoutRequest);
	}

	const handleModalClose = () => {
		setOpenModal(false);
	};
	return (
		<AppBar
			position="static"
			elevation={0}
			sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
			style={{
				paddingLeft: '50px',
				paddingRight: '50px',
				background: theme.palette.background.default,
				color: theme.palette.primary.dark,
			}}
		>
			<Toolbar disableGutters>
				<Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'flex' } }}>
					<img
						src={logo}
						alt="CONTROL RISKS LOGO"
						width={'184px'}
						height={'34px'}
						loading="lazy"
					/>
				</Box>
				<Box
					sx={{
						flexGrow: 0,
						display: 'flex',
						alignItems: 'center',
						gap: '12px',
					}}
				>
					<Box>
						Welcome,
						<span style={{ fontWeight: '600' }}>
							{decoded && ` ${decoded.given_name} ${decoded.family_name}`}
						</span>
					</Box>
					<Avatar
						{...stringAvatar(`${decoded?.given_name} ${decoded?.family_name}`)}
					/>
					<ClickAwayListener onClickAway={() => setShowPopUp(false)}>
						<Box
							sx={{
								position: 'relative',
							}}
						>
							<IconButton
								onClick={() => setShowPopUp(showPopUp ? false : true)}
								sx={{
									p: 0,
									backgroundColor: theme.palette.background.default,
									'&&:hover': {
										backgroundColor: theme.palette.background.default,
									},
								}}
							>
								{showPopUp ? <CloseIcon /> : <DownArrow />}
							</IconButton>
							<Box
								sx={{
									padding: '0px 15px',
									position: 'absolute',
									boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
									borderRadius: '8px',
									borderBottom: `3px solid ${theme.palette.secondary.dark}`,
									backgroundColor: theme.palette.primary.light,
									top: '35px',
									right: '0',
									zIndex: '1',
									minWidth: '240px',
									height: 'auto',
									display: showPopUp ? 'block' : 'none',
									'& > div': {
										paddingY: '10px',
										borderBottom: `1px solid ${theme.palette.secondary.dark}`,
									},
									'& :last-child': {
										borderBottom: 'none',
									},
								}}
							>
								<Box
									onClick={() => setOpenModal(true)}
									sx={{
										padding: '0 10px',
										cursor: 'pointer',
										flexGrow: 0,
										display: 'flex',
										alignItems: 'center',
										gap: '5px',
									}}
								>
									<LogOutIcon />
									<span
										style={{
											color: theme.palette.text.primary,
											fontSize: '16px',
											lineHeight: '16px',
											fontWeight: '400',
										}}
									>
										Log out
									</span>
								</Box>
							</Box>
						</Box>
					</ClickAwayListener>
				</Box>
			</Toolbar>
			<BootstrapDialog
				onClose={handleModalClose}
				aria-labelledby="dialog-title"
				open={openModal}
				sx={{
					'& .MuiPaper-root': {
						borderTop: `10px solid ${theme.palette.primary.main}`,
						background: theme.palette.background.default,
						width: '600px',
						height: '222px',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						gap: '20px',
					},
				}}
			>
				<p
					style={{
						fontSize: '16px',
						fontWeight: '400',
						fontStyle: 'italic',
					}}
				>
					Are you sure you want to log out?
				</p>
				<Box>
					<Button
						onClick={() => setOpenModal(false)}
						sx={{
							padding: '11px 20px',
							background: theme.palette.background.default,
							color: theme.palette.info.contrastText,
							marginRight: '20px',
							fontWeight: '600',
							textTransform: 'none',
							'&:hover': {
								backgroundColor: theme.palette.background.default,
							},
						}}
					>
						Cancel
					</Button>
					<Button
						onClick={() => signOutClickHandler(instance)}
						sx={{
							padding: '11px 38px',
							borderRadius: '7px',
							background: theme.palette.primary.main,
							color: theme.palette.background.default,
							fontWeight: '600',
							textTransform: 'none',
							width: '180px',
							'&:hover': {
								backgroundColor: theme.palette.primary.main,
							},
						}}
					>
						Log out
					</Button>
				</Box>
			</BootstrapDialog>
		</AppBar>
	);
};
export default memo(Header);
