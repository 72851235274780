import React, { useEffect, useContext, Suspense } from 'react';
import { useNavigate } from 'react-router-dom';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';
import { Config } from '../../config';
import Loading from '../atoms/loading';

const ProtectedRoute = ({ children }) => {
	const { instance, inProgress, accounts } = useMsal();
	const { setToken, token, scope } = useContext(Config);
	const navigate = useNavigate();
	const isAuthenticated = useIsAuthenticated();

	useEffect(() => {
		if (inProgress === 'none' && accounts.length > 0) {
			const request = {
				account: accounts[0],
				scopes: [scope],
			};
			// Retrieve an access token
			instance
				.acquireTokenSilent(request)
				.then((response) => {
					if (response.accessToken) {
						setToken(response.accessToken);
					}
				})
				.catch((error) => console.log('token error', error));
		}
		// eslint-disable-next-line
	}, [inProgress, accounts]);

	useEffect(() => {
		if (!isAuthenticated && inProgress === InteractionStatus.None) {
			// user is not authenticated
			instance
				.loginRedirect({
					scopes: ['openid', 'profile'],
				})
				.then(() => {
					navigate('/');
				})
				.catch((e) => {});
		}
		// eslint-disable-next-line
	}, [isAuthenticated, inProgress, instance]);

	if (!token) {
		return <Loading text="Authenticating" position="absolute" />;
	}

	return (
		<Suspense fallback={<Loading text="Loading" position="absolute" />}>
			{children}
		</Suspense>
	);
};

export default ProtectedRoute;
