import { Box, useTheme } from '@mui/material';
import { memo } from 'react';
import { NavLink } from 'react-router-dom';

const Breadcrumb = () => {
	const theme = useTheme();

	return (
		<Box
			component="nav"
			aria-label="breadcrumb"
			sx={{
				px: {
					xs: '10px',
					md: '50px',
				},
				py: '16px',
				display: 'flex',
				gap: '10px',
				backgroundColor: theme.palette.info.main,
				color: theme.palette.common.white,
				fontSize: '16px',
			}}
		>
			<NavLink
				to="/"
				style={({ isActive }) => ({
					color: theme.palette.common.white,
					textDecoration: 'none',
					fontWeight: isActive ? '700' : 'normal',
				})}
			>
				Home
			</NavLink>
			<NavLink
				to="/addUsers"
				style={({ isActive }) => ({
					color: theme.palette.common.white,
					textDecoration: 'none',
					fontWeight: isActive ? '700' : 'normal',
					display: isActive ? 'block' : 'none',
				})}
			>
				/ Add a user
			</NavLink>
		</Box>
	);
};

export default memo(Breadcrumb);
