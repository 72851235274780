import { styled } from '@mui/system';
import { InputTypesProps } from './types';

export const CustomInput = styled('input')<InputTypesProps>(
	({ error, theme, fullWidth }) => ({
		padding: '12px 14px',
		borderRadius: '5px',
		fontSize: '16px',
		lineHeight: '17px',
		fontWeight: '300',
		outline: 'none',
		border: `1px solid ${
			error ? theme.palette.error.main : theme.palette.primary.contrastText
		}`,
		background: theme.palette.background.paper,
		boxSizing: 'border-box',
		width: fullWidth ? '100%' : 'initial',
		'&:focus': {
			border: `2px solid ${
				error ? theme.palette.error.main : theme.palette.primary.dark
			} !important`,
		},

		'&:hover': !error && {
			border: `1px solid ${theme.palette.primary.dark}`,
		},
	})
);

export const CustomLabel = styled('p')(({ theme }) => ({
	fontSize: '16px',
	color: theme.palette.primary.main,
	margin: '0 0 6px 0',
}));

export const CustomError = styled('p')(({ theme }) => ({
	margin: '3px 0 0 14px',
	color: theme.palette.error.main,
	fontSize: '12px',
	fontWeight: 400,
}));
