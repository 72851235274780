import { Box } from '@mui/material';
import React, { FC, PropsWithChildren } from 'react';
import Header from './header';
import Footer from './footer';
import Breadcrumb from './breadcrumb';

const MainLayout: FC<PropsWithChildren> = (props) => {
	const { children } = props;
	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				minHeight: '100vh',
			}}
		>
			<Header />
			<Breadcrumb />
			<Box
				component="main"
				sx={{
					flexGrow: 1,
					display: 'flex',
					flexDirection: 'column',
				}}
			>
				{children}
			</Box>
			<Footer />
		</Box>
	);
};

export default MainLayout;
