import * as React from 'react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
	Tabs,
	Tab,
	Button,
	Typography,
	Grid,
	Box,
	useTheme,
	Divider,
	TextField,
	Autocomplete,
	Stack,
} from '@mui/material';
import FileDropZone from '../components/molecules/fileDropZone/FileDropZone';
import * as yup from 'yup';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useFetch } from 'hooks';
import Alert from 'components/atoms/alert/alert';
import Input from 'components/atoms/input/Input';
import Loading from 'components/atoms/loading';
import { Dialog } from 'components/molecules/dialog';
import { Banner } from 'components/molecules';
import BulkCreationForm from '../components/organisms/BulkCreationForm';
import { useMsal } from '@azure/msal-react';
import CheckIcon from '@mui/icons-material/Check';
import { ADB2CGroupChips } from 'components/molecules/adb2cGroupChips';

type User = {
	id: string;
	firstname: string;
	surname: string;
	email: string;
	jobTitle: string;
	department: string;
	companyName: string;
	invitationSendDate?: string;
	companyId?: string;
};

type ADUser = {
	userId: string;
	firstname: string;
	surname: string;
	displayName: string;
	emailAddress: string;
	companyId: string;
	companyName: string;
};
interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
}
type DebounceFunction<T extends any[]> = (...args: T) => void;

const CustomSubmitButton = {
	fontWeight: '400',
	fontSize: '12px',
	lineHeight: '14px',
	color: '#FFFFFF',
	width: '146px',
	height: '35px',
	background: '#003D50',
	margin: '10px',
};
const CustomSubmitDisabledButton = {
	fontWeight: '400',
	fontSize: '12px',
	lineHeight: '14px',
	color: '#FFFFFF',
	width: '146px',
	height: '35px',
	background: '#CACDCD',
	margin: '10px',
};
const CustomResetButton = {
	fontWeight: '400',
	fontSize: '12px',
	lineHeight: '14px',
	color: '#003D50',
	width: '146px',
	height: '35px',
	border: '1px solid #003D50',
	margin: '10px',
};

function CustomTabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 3 }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

function a11yProps(index: number) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

function debounce<T extends any[]>(
	func: DebounceFunction<T>,
	delay: number
): DebounceFunction<T> {
	let timeoutId: NodeJS.Timeout;

	return function (this: any, ...args: T) {
		clearTimeout(timeoutId);
		timeoutId = setTimeout(() => {
			func.apply(this, args);
		}, delay);
	};
}

const schema = yup.object().shape({
	email: yup
		.string()
		.email('Must be a valid email')
		.max(255)
		.required('Email address is a required field'),
	/*displayName: yup
		.string()
		.max(255)
		.required('Display name is a required field'),*/
	givenName: yup.string().max(255).required('First name is a required field'),
	surname: yup.string().max(255).required('Last name is a required field'),
	companyName: yup
		.string()
		.max(255)
		.required('Company name is a required field'),
	companyId: yup
		.string()
		.max(255)
		.required('CRM account number is a required field'),

	department: yup.string().max(255).required('Location is a required field'),

	groups: yup.mixed().test('groups', 'Service is a required field', (value) => {
		if (typeof value === 'object') {
			return value?.length > 0;
		}
		return value !== '';
	}),
});

const CreateUser = () => {
	const [successOpen, setSuccessOpen] = useState(false);
	const [errorOpen, setErrorOpen] = useState(false);
	const [dialogOpen, setDialogOpen] = useState(false);
	const [response, setResponse] = useState('');
	const theme = useTheme();
	const [isCreatingUser, setIsCreatingUser] = useState(false);
	//const { groupId } = useContext(Config);
	const { accounts } = useMsal();
	const [crEmailInputValue, setCrEmailInputValue] = useState('');
	const navigate = useNavigate();
	const successAlertMessage = (
		<Alert
			isOpen={errorOpen}
			setIsOpen={setErrorOpen}
			message={'' + response}
			severity="error"
			color={theme.palette.error.main}
		/>
	);
	const errorAlertMessage = (
		<Alert
			isOpen={successOpen}
			setIsOpen={setSuccessOpen}
			message="User was successfully created."
			severity="success"
			color={theme.palette.success.main}
		/>
	);

	const errorUnavailableServiceMessage = (
		<Alert
			isOpen={errorOpen}
			setIsOpen={setErrorOpen}
			message={'Services unavailable .'}
			severity="error"
			color={theme.palette.error.main}
		/>
	);
	const cancelDialog = (
		<Dialog
			open={dialogOpen}
			message={
				'If you cancel any unsaved data will be lost. Do you want to proceed?'
			}
			title={'Cancel'}
			onConfirm={() => triggerReset()}
			onClose={() => setDialogOpen(false)}
			onCancel={() => setDialogOpen(false)}
			loading={false}
		/>
	);

	const [groupArray, setGroupArray] = useState(Array<any>);

	function triggerReset() {
		if (tab > 1) {
			setDialogOpen(false);
			removeFile();
			setBulkUsers([]);
			navigate('/');
		} else {
			setDialogOpen(false);
			reset();
			setGroupArray([]);
			setTimeout(function () {
				setGroupArray(groups);
			}, 500);
			navigate('/');
		}
	}

	const [tab, setTab] = React.useState(0);

	const {
		register,
		handleSubmit,
		getValues,
		control,
		reset,
		watch,
		setValue,
		formState: { errors },
	} = useForm({
		mode: 'all',
		resolver: yupResolver(schema),
	});
	console.log(errors);
	const watchGroups = watch('groups');
	console.log(watchGroups);

	//const varWatcher = watch();

	const { fetchLazy } = useFetch(undefined, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: null,
		onSuccess: () => {
			setSuccessOpen(true);
			setIsCreatingUser(false);
			reset();
			if (tab > 1) {
				setBulkSaveSuccess(true);
			}
		},
		onError: (responseStatus) => {
			setResponse(responseStatus);
			setIsCreatingUser(false);
			setErrorOpen(true);
			reset();
			if (tab > 1) {
				setBulkSaveSuccess(false);
			}
		},
	});

	const { data: users } = useFetch<User[]>('user/users', {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
		},
		body: null,
		onSuccess: (data) => {},
		onError: (responseStatus) => {
			setErrorOpen(true);
		},
	});

	const { fetchLazy: getAdUsers } = useFetch<ADUser[]>(undefined, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
		},
		body: null,
		onError: (responseStatus) => {
			setErrorOpen(true);
		},
		onSuccess: (data) => {
			if (data) setEmailArray(data);
		},
	});

	const { data: groups, fetchLazy: getGroups } = useFetch<any>(undefined, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
		},
		body: null,
		onError: (responseStatus) => {
			setResponse(responseStatus);
			setErrorOpen(true);
		},
		onSuccess: (data) => {
			setGroupArray(data);
		},
	});

	useEffect(() => {
		getGroups(
			`oidc/groups`,
			{
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
				},
			},
			false
		);
		// eslint-disable-next-line
	}, []);

	const onSubmit = () => {
		// let obj = getValues().groups;
		// let id;
		// let displayName;

		// for (const [key, value] of Object.entries(obj)) {
		// 	if (key === 'id') {
		// 		id = value;
		// 	}
		// 	if (key === 'displayName') {
		// 		displayName = value;
		// 	}
		// }

		let selectedGroups = ''; //: string[] = [];

		userSelectedGroups?.forEach((el, index) => {
			const x = groupArray.find((group) => group.id === el);
			if (x) {
				//selectedGroups.push(x.id);

				if (index !== 0) {
					selectedGroups = selectedGroups + ',' + x.id;
				} else {
					selectedGroups = selectedGroups + x.id;
				}
			}
		});

		/*const selectedGroups1 = groupArray.find(
			(group) => group.id === getValues().groups
		);*/

		//setIsCreatingUser(true);

		fetchLazy(
			'user/create',
			{
				body: JSON.stringify({
					...getValues(),
					createdBy: accounts.at(0)?.name || '',
					groups: {
						id: selectedGroups,
						displayName: 'Client Dashboard',
					},
				}),
			},
			false
		);
	};

	const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
		reset();
		setTab(newValue);
	};
	const [emailArray, setEmailArray] = useState(Array<any>);

	const debouncedFetch = React.useMemo(() => {
		return debounce((inputValue) => {
			if (inputValue.length === 0) {
				setEmailArray([]);
				return;
			} else {
				getAdUsers(
					`user/all-staff-group?query=${inputValue}`,
					{
						method: 'GET',
						headers: {
							'Content-Type': 'application/json',
						},
					},
					false
				);
			}
		}, 400);
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		debouncedFetch(crEmailInputValue);
		// eslint-disable-next-line
	}, [crEmailInputValue]);

	const [userSelectedGroups, setUserSelectedGroups] = useState<any[]>([]);

	useEffect(() => {
		setValue('groups', userSelectedGroups);
		// eslint-disable-next-line
	}, [userSelectedGroups]);

	function ExternalUserForm() {
		return (
			<form onSubmit={handleSubmit(onSubmit)}>
				<Box>
					<Controller
						name="companyId"
						control={control}
						render={({
							field: { onChange, value },
							fieldState: { error, invalid },
						}) => (
							<Input
								formController={{ ...register('companyId') }}
								type="text"
								required
								label="CRM account number"
								placeholder="Enter CRM account number"
								id="companyId"
								fullWidth
								error={!!errors?.companyId}
								helperText={invalid ? error?.message : null}
							/>
						)}
					/>
				</Box>
				<Box>
					<Controller
						name="companyName"
						control={control}
						render={({
							field: { onChange, value },
							fieldState: { error, invalid },
						}) => (
							<Input
								formController={{ ...register('companyName') }}
								type="text"
								required
								label="Company name"
								placeholder="Enter Company name"
								id="companyName"
								fullWidth
								error={!!errors?.companyName}
								helperText={invalid ? error?.message : null}
							/>
						)}
					/>
				</Box>
				<Box>
					<Controller
						name="email"
						control={control}
						render={({
							field: { onChange, value },
							fieldState: { error, invalid },
						}) => (
							<Input
								formController={{ ...register('email') }}
								type="text"
								required
								label="Email"
								placeholder="Enter Email"
								id="email"
								fullWidth
								error={!!errors?.email}
								helperText={invalid ? error?.message : null}
							/>
						)}
					/>
				</Box>

				<Grid container spacing={3}>
					<Grid item xs={6}>
						<Controller
							name="givenName"
							control={control}
							render={({
								field: { onChange, value },
								fieldState: { error, invalid },
							}) => (
								<Input
									formController={{ ...register('givenName') }}
									type="text"
									required
									label="First name"
									placeholder="Enter First name"
									id="givenName"
									fullWidth
									error={!!errors?.givenName}
									helperText={invalid ? error?.message : null}
								/>
							)}
						/>
					</Grid>
					<Grid item xs={6}>
						<Controller
							name="surname"
							control={control}
							render={({
								field: { onChange, value },
								fieldState: { error, invalid },
							}) => (
								<Input
									formController={{ ...register('surname') }}
									type="text"
									required
									label="Last name"
									placeholder="Enter Last name"
									id="surname"
									fullWidth
									error={!!errors?.surname}
									helperText={invalid ? error?.message : null}
								/>
							)}
						/>
					</Grid>
				</Grid>

				<div hidden>
					<Box>
						<Controller
							name="displayName"
							control={control}
							render={({
								field: { onChange, value },
								fieldState: { error, invalid },
							}) => (
								<Input
									formController={{ ...register('displayName') }}
									type="text"
									required
									label="displayNameasdf"
									placeholder="displayName"
									id="displayName"
									value={'null'}
									readOnly={true}
									fullWidth
									error={!!errors?.displayName}
									helperText={invalid ? error?.message : null}
								/>
							)}
						/>
					</Box>
				</div>

				<Box>
					<Controller
						name="department"
						control={control}
						render={({
							field: { onChange, value },
							fieldState: { error, invalid },
						}) => (
							<Input
								formController={{ ...register('department') }}
								type="text"
								label="Location"
								placeholder="Enter Location"
								id="department"
								fullWidth
								required
								error={!!errors?.department}
								helperText={invalid ? error?.message : null}
							/>
						)}
					/>
				</Box>

				<Stack>
					<Stack>
						<Controller
							name="groups"
							control={control}
							render={({
								field: { onChange, value },
								fieldState: { error, invalid, isTouched, isDirty },
							}) => (
								<Input
									formController={{ ...register('groups') }}
									required
									label="Service"
									placeholder="Enter service"
									id="groups"
									fullWidth
									defaultValue=""
									error={
										!!errors?.groups ||
										(isTouched && isDirty && userSelectedGroups.length === 0)
									}
									selectValues={groupArray
										.filter(
											(group) =>
												group.displayName.toLowerCase() === 'client portal' ||
												group.displayName.toLowerCase() ===
													'client dashboard' ||
												group.displayName.toLowerCase() === 'mycontrolrisks'
										)
										.sort((a, b) => a.displayName.localeCompare(b.displayName))}
									onChange={(e): any => {
										setUserSelectedGroups((oldArray) => {
											if (oldArray.find((x) => x === e.target.value)) {
												return [...oldArray];
											} else {
												return [...oldArray, e.target.value];
											}
										});
									}}
									helperText={
										invalid
											? error?.message
											: isTouched && isDirty && userSelectedGroups.length === 0
											? 'Service is a required field'
											: null
									}
								/>
							)}
						/>
					</Stack>
					<Stack>
						<ADB2CGroupChips
							groups={groupArray.filter((g) =>
								userSelectedGroups.find((u) => u === g.id)
							)}
							editable={true}
							setUserSelectedGroups={setUserSelectedGroups}
							userSelectedGroups={userSelectedGroups}
						/>
					</Stack>
				</Stack>

				<Box sx={{ float: 'right' }}>
					<Button
						sx={{ textTransform: 'none' }}
						variant="text"
						onClick={() => {
							setDialogOpen(true);
						}}
						style={CustomResetButton}
					>
						Cancel
					</Button>
					<Button
						sx={{ textTransform: 'none' }}
						variant="outlined"
						style={CustomSubmitButton}
						type="submit"
					>
						Create
					</Button>
				</Box>
			</form>
		);
	}
	function ControlRisksUserForm() {
		return (
			<form onSubmit={handleSubmit(onSubmit)}>
				<div style={{ pointerEvents: 'none' }}>
					<Box>
						<Controller
							name="companyId"
							control={control}
							render={({
								field: { onChange, value },
								fieldState: { error, invalid },
							}) => (
								<Input
									formController={{ ...register('companyId') }}
									type="text"
									required
									label="CRM account number"
									placeholder="Enter CRM account number"
									id="companyId"
									value="001"
									readOnly={true}
									fullWidth
									textColor="#595958"
									backgroundColor="#B5B5B5"
									error={!!errors?.companyId}
									helperText={invalid ? error?.message : null}
								/>
							)}
						/>
					</Box>
					<Box>
						<Controller
							name="companyName"
							control={control}
							render={({
								field: { onChange, value },
								fieldState: { error, invalid },
							}) => (
								<Input
									formController={{ ...register('companyName') }}
									type="text"
									required
									label="Company name"
									placeholder="Enter Company name"
									id="companyName"
									value="Control Risks Ltd."
									readOnly={true}
									textColor="#595958"
									backgroundColor="#B5B5B5"
									fullWidth
									error={!!errors?.companyName}
									helperText={invalid ? error?.message : null}
								/>
							)}
						/>
					</Box>
				</div>
				<Box>
					<Controller
						name="email"
						control={control}
						render={({ field: { onChange, value } }) => (
							<Box>
								<p
									style={{
										fontSize: '16px',
										color: theme.palette.primary.main,
										margin: '0px 0px 0px 0px',
									}}
								>
									Email <span style={{ color: 'red' }}>*</span>
								</p>
								<Autocomplete
									onChange={(event, item) => {
										onChange(item);
										setEmailArray(item ? [item, ...emailArray] : emailArray);
									}}
									onInputChange={(event, newInputValue) => {
										setCrEmailInputValue(newInputValue);
									}}
									value={value}
									disablePortal
									options={emailArray.map((e) => e.emailAddress)}
									getOptionLabel={(item) => (item ? item : '')}
									size="small"
									sx={{
										'& .MuiFormControl-root': {
											margin: 0,
										},
									}}
									renderInput={(params) => (
										<TextField
											{...params}
											margin="normal"
											variant="outlined"
											label="Enter Email"
											error={!!errors?.email}
											helperText={errors.email && 'Email is a required field'}
										/>
									)}
								/>
							</Box>
						)}
					/>
				</Box>

				<Grid container spacing={3}>
					<Grid item xs={6}>
						<Controller
							name="givenName"
							control={control}
							render={({
								field: { onChange, value },
								fieldState: { error, invalid },
							}) => (
								<Input
									formController={{ ...register('givenName') }}
									type="text"
									required
									label="First name"
									placeholder="Enter First name"
									id="givenName"
									fullWidth
									error={!!errors?.givenName}
									helperText={invalid ? error?.message : null}
								/>
							)}
						/>
					</Grid>
					<Grid item xs={6}>
						<Controller
							name="surname"
							control={control}
							render={({
								field: { onChange, value },
								fieldState: { error, invalid },
							}) => (
								<Input
									formController={{ ...register('surname') }}
									type="text"
									required
									label="Last name"
									placeholder="Enter Last name"
									id="surname"
									fullWidth
									error={!!errors?.surname}
									helperText={invalid ? error?.message : null}
								/>
							)}
						/>
					</Grid>
				</Grid>

				<div hidden>
					<Box>
						<Controller
							name="displayName"
							control={control}
							render={({
								field: { onChange, value },
								fieldState: { error, invalid },
							}) => (
								<Input
									formController={{ ...register('displayName') }}
									type="text"
									required
									label="displayNameasdf"
									placeholder="displayName"
									id="displayName"
									value={'null'}
									readOnly={true}
									fullWidth
									error={!!errors?.displayName}
									helperText={invalid ? error?.message : null}
								/>
							)}
						/>
					</Box>
				</div>

				<Box>
					<Controller
						name="department"
						control={control}
						render={({
							field: { onChange, value },
							fieldState: { error, invalid },
						}) => (
							<Input
								formController={{ ...register('department') }}
								type="text"
								label="Location"
								placeholder="Enter Location"
								id="department"
								fullWidth
								required
								error={!!errors?.department}
								helperText={invalid ? error?.message : null}
							/>
						)}
					/>
				</Box>
				<Stack>
					<Stack>
						<Controller
							name="groups"
							control={control}
							render={({
								field: { onChange, value },
								fieldState: { error, invalid },
							}) => (
								<Input
									formController={{ ...register('groups') }}
									required
									label="Service"
									placeholder="Enter service"
									id="groups"
									fullWidth
									defaultValue=""
									error={!!errors?.groups}
									selectValues={groupArray
										.filter(
											(group) =>
												group.displayName.toLowerCase() === 'client portal' ||
												group.displayName.toLowerCase() ===
													'client dashboard' ||
												group.displayName.toLowerCase() === 'mycontrolrisks'
										)
										.sort((a, b) => a.displayName.localeCompare(b.displayName))}
									onChange={(e): any => {
										setUserSelectedGroups((oldArray) => {
											if (oldArray.find((x) => x === e.target.value)) {
												return [...oldArray];
											} else {
												return [...oldArray, e.target.value];
											}
										});
									}}
									helperText={invalid ? error?.message : null}
								/>
							)}
						/>
					</Stack>
					<Stack>
						<ADB2CGroupChips
							groups={groupArray.filter((g) =>
								userSelectedGroups.find((u) => u === g.id)
							)}
							editable={true}
							setUserSelectedGroups={setUserSelectedGroups}
							userSelectedGroups={userSelectedGroups}
						/>
					</Stack>
				</Stack>

				<Box sx={{ float: 'right' }}>
					<Button
						sx={{ textTransform: 'none' }}
						variant="text"
						onClick={() => {
							setDialogOpen(true);
						}}
						style={CustomResetButton}
					>
						Cancel
					</Button>
					<Button
						sx={{ textTransform: 'none' }}
						variant="outlined"
						style={CustomSubmitButton}
						type="submit"
					>
						Create
					</Button>
				</Box>
			</form>
		);
	}

	/*
	email autocomplete component. do not remove as it will be needed in the future.
	{
		emailArray.length > 0 && (
			<Box>
				<Controller
					name="email"
					control={control}
					render={({ field: { onChange, value } }) => (
						<Box>
							<p style={{ fontSize: '16px', color: theme.palette.primary.main, margin: '0px 0px 0px 0px', }}>
								Email <span style={{ color: 'red' }}>*</span>
							</p>
							<Autocomplete
								onChange={(event, item) => {
									onChange(item);
									setEmailAutocompleteValue(item);
								}}
								value={emailAutocompleteValue}
								disablePortal
								options={emailArray}
								getOptionLabel={(item) => (item ? item : '')}
								renderInput={(params) => (
									<TextField
										{...params}
										margin="normal"
										variant="outlined"
										label="Enter Email"
										error={!!errors?.email}
										helperText={errors.email && 'Email is a required field'}
									/>
								)}
							/>
						</Box>
					)}
				/>
			</Box>
		)
	}
	*/
	const [files, setFiles] = useState<any[]>([]);
	const [validFile, setValidFile] = useState<boolean>(true);
	const [showCreateButton, setShowCreateButton] = useState<boolean>(false);
	const [bulkUsers, setBulkUsers] = useState<any[]>([]);
	const [errorsInFile, setErrorsInFile] = useState<boolean>(true);
	const [bulkSaveSuccess, setBulkSaveSuccess] = useState<boolean>(false);
	function saveBulkUsers() {
		if (bulkUsers) {
			let bodyarr: Array<any> = [];

			bulkUsers.forEach((x) => {
				let groupArr = x[6].split(',');

				let groupData = '';

				groupArr.forEach((g, index) => {
					let search = g.toLowerCase().trim();
					if (
						g.toLowerCase().trim() === 'client portal' ||
						g.toLowerCase().trim() === 'client dashboard'
					) {
						search = 'Client Dashboard';
					} else if (
						g.toLowerCase().trim() === 'mycontrolrisks' ||
						g.toLowerCase().trim() === 'mycr'
					) {
						search = 'MyControlRisks';
					}

					let result = groups.filter((group: any) => {
						return group.displayName.toLowerCase() === search.toLowerCase();
					});

					if (result) {
						if (index !== 0) {
							groupData = groupData + ',' + result[0].id;
						} else {
							groupData = groupData + result[0].id;
						}
					}
				});

				let b = {
					companyId: x[0].toString(),
					companyName: x[1],
					email: x[2],
					givenName: x[3],
					surname: x[4],
					department: x[5],
					groups: { displayName: 'Client Dashboard', id: groupData },
					createdBy: accounts.at(0)?.name || '',
					displayName: 'null',
				};
				bodyarr.push(b);
			});

			fetchLazy(
				'user/bulk-create',
				{
					body: JSON.stringify(bodyarr),
				},
				false
			);
		}
	}

	function BulkRegisterUserForm() {
		return (
			<Grid container direction="column">
				{bulkSaveSuccess ? (
					<>
						<Grid container justifyContent="center">
							<Grid item xs={1}>
								<CheckIcon
									color="primary"
									sx={{
										width: '96px',
										height: '96px',
										backgroundColor: '#008475',
										borderRadius: '50%',
										color: 'white',
									}}
								/>
							</Grid>
						</Grid>
						<Typography
							sx={{
								textAlign: 'center',
								margin: '5px',
								color: '#224957',
								fontSize: '28px',
							}}
						>
							{' '}
							Users successfully created{' '}
						</Typography>
						<Typography
							sx={{
								textAlign: 'center',
								margin: '5px',
								color: '#008475',
								fontSize: '20px',
							}}
						>
							{' '}
							{bulkUsers.length} new users{' '}
						</Typography>
					</>
				) : (
					<Grid container>
						<Grid item xs={4}>
							<FileDropZone
								onChange={updateFiles}
								onDelete={removeFile}
								files={files}
								label={'Upload file(s) \n Drag here or select'}
							></FileDropZone>

							<span style={{ color: theme.palette.error.light }}>
								{/*validFile ? null : 'Please upload a valid excel file'*/}
							</span>
						</Grid>
					</Grid>
				)}

				<Grid container>
					{validFile && users && files[0] && (
						<BulkCreationForm
							file={files[0].file}
							users={users}
							usersToSave={bulkUsers}
							setUsersToSave={setBulkUsers}
							groups={groups}
							errorsInFile={errorsInFile}
							setErrorsInFile={setErrorsInFile}
							bulkSaveSuccess={bulkSaveSuccess}
						/>
					)}
				</Grid>
				<Grid container justifyContent="flex-end" alignItems="flex-end">
					{!bulkSaveSuccess ? (
						<>
							<Button
								sx={{ textTransform: 'none' }}
								variant="text"
								onClick={() => {
									setDialogOpen(true);
								}}
								style={CustomResetButton}
							>
								Cancel
							</Button>
							{validFile && showCreateButton ? (
								<>
									{!errorsInFile ? (
										<>
											<Button
												sx={{ textTransform: 'none' }}
												variant="outlined"
												style={CustomSubmitButton}
												onClick={() => {
													saveBulkUsers();
												}}
											>
												Create
											</Button>
										</>
									) : (
										<>
											<Button
												disabled
												sx={{ textTransform: 'none' }}
												variant="outlined"
												style={CustomSubmitDisabledButton}
												onClick={() => {
													saveBulkUsers();
												}}
											>
												Create
											</Button>
										</>
									)}
								</>
							) : (
								<></>
							)}
						</>
					) : (
						<>
							<Button
								sx={{ textTransform: 'none' }}
								variant="text"
								onClick={() => {
									navigate('/');
								}}
								style={CustomSubmitButton}
							>
								Back to users
							</Button>
						</>
					)}
				</Grid>
			</Grid>
		);
	}

	const updateFiles = (incomingFiles: any) => {
		if (incomingFiles) {
			/*let type = incomingFiles[0].file.name
				.split('.')
				.slice(-1)
				.toString()
				.toLowerCase();*/
		}
		setFiles(incomingFiles);
	};

	const removeFile = () => {
		setFiles([]);
	};

	useEffect(() => {
		if (files) {
			if (files[0]) {
				if (
					files[0].file.type ===
						'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
					files[0].file.type === 'application/vnd.ms-excel' ||
					files[0].file.type === 'text/csv' ||
					files[0].file.type ===
						'application/vnd.ms-excel.sheet.binary.macroEnabled.12' ||
					files[0].file.type ===
						'application/vnd.ms-excel.sheet.macroEnabled.12'
				) {
					setValidFile(true);
					setShowCreateButton(true);
				} else {
					setValidFile(false);
					setShowCreateButton(true);
				}
			}
		}
		// eslint-disable-next-line
	}, [files]);

	if (!groups && !errorOpen) {
		return (
			<div>
				<Loading text="Loading groups..."></Loading>
			</div>
		);
	}
	if (isCreatingUser) {
		return (
			<div>
				<Loading text="Creating User..."></Loading>
			</div>
		);
	}
	if (!groups && errorOpen) {
		return errorUnavailableServiceMessage;
	}
	return (
		<Box>
			{successAlertMessage}
			{errorAlertMessage}
			{cancelDialog}
			{tab !== 2 ? (
				<Banner title="Add a user" />
			) : (
				<>
					{bulkSaveSuccess || files.length > 0 ? (
						<Banner title="Add users" />
					) : (
						<Banner title="Add users" showDownloadButton={true} />
					)}
				</>
			)}
			<Box className="gridStyle">
				<Grid
					container
					direction="column"
					alignItems="center"
					justifyContent="top"
					display="flex"
					sx={{
						minHeight: 700,
						width: '100%',
						backgroundImage: 'linear-gradient(#99B1B9 15%,#FFF,#FFF)',
					}}
				>
					<Grid
						direction="column"
						container
						justifyContent="center"
						sx={{
							backgroundColor: 'white',
							width: '95%',
							height: '80%',
							margin: '20px',
							borderRadius: '10px',
							padding: '30px',
						}}
					>
						{bulkSaveSuccess ? (
							<></>
						) : (
							<>
								<Grid item>
									<Tabs
										value={tab}
										onChange={handleChangeTab}
										aria-label="basic tabs example"
									>
										<Tab
											sx={{ textTransform: 'none' }}
											label="External"
											{...a11yProps(0)}
										/>
										<Tab
											sx={{ textTransform: 'none' }}
											label="Control Risks"
											{...a11yProps(1)}
										/>
										<Tab
											sx={{ textTransform: 'none' }}
											label="Bulk Creation"
											{...a11yProps(2)}
										/>
									</Tabs>
								</Grid>
							</>
						)}

						<Divider />

						<Grid item sx={{ width: '100%' }}>
							<CustomTabPanel value={tab} index={0}>
								{ExternalUserForm()}
							</CustomTabPanel>
							<CustomTabPanel value={tab} index={1}>
								{ControlRisksUserForm()}
							</CustomTabPanel>
							<CustomTabPanel value={tab} index={2}>
								{BulkRegisterUserForm()}
							</CustomTabPanel>
						</Grid>
					</Grid>
				</Grid>
			</Box>
		</Box>
	);
};
export default CreateUser;
