import { InputTypesProps } from './types';
import { MenuItem, Select, TextField, Typography, useTheme } from '@mui/material';
import { CustomLabel, CustomError } from './Input.styles';

export default function Input({
	id,
	label,
	placeholder,
	type,
	defaultValue,
	value,
	labelStyles,
	inputStyles,
	required,
	fullWidth,
	formController,
	error,
	helperText,
	readOnly,
	multiline,
	disabled,
	InputProps,
	backgroundColor,
	textColor,
	onChange,
	rows = 0,
	selectValues = [],
}: InputTypesProps) {
	const theme = useTheme();
	return (
		<>
			{label && (
				<CustomLabel
					style={{ color: '#224957' }}
					theme={theme}
					sx={{ ...labelStyles }}
				>
					{label}
					{required && <span style={{ color: '#E83C4E' }}> *</span>}
				</CustomLabel>
			)}
			{selectValues.length === 0 && (
				<TextField
					{...formController}
					defaultValue={defaultValue}
					value={value}
					id={id}
					theme={theme}
					fullWidth={fullWidth}
					error={error}
					placeholder={placeholder}
					type={type}
					readOnly={readOnly}
					onChange={onChange}
					multiline={multiline}
					rows={rows}
					disabled={disabled}
					InputProps={InputProps}
					sx={{
						'& .MuiInputBase-input': {
							...inputStyles,
							padding: '10.5px 14px',
							borderRadius: '4px',
							backgroundColor:
								backgroundColor || theme.palette.background.default,
						},
						'& .MuiInputBase-root': {
							backgroundColor:
								backgroundColor || theme.palette.background.default,
							padding: '0',
						},
						input: {
							color: textColor || 'black',
						},
					}}
				></TextField>
			)}
			{selectValues.length > 0 && (
				<Select
					{...formController}
					defaultValue={defaultValue}
					value={value}
					id={id}
					theme={theme}
					fullWidth={fullWidth}
					error={error}
					placeholder={placeholder}
					type={type}
					select={selectValues?.length > 0}
					readOnly={readOnly}
					onChange={onChange}
					multiline={multiline}
					rows={rows}
					disabled={disabled}
					displayEmpty
					renderValue={() => {
						return (
							<Typography sx={{ color: '#7E8081', fontWeight:300, fontSize:'16px' }}>Select service</Typography>
						);
					}}
					InputProps={InputProps}
					sx={{
						'& .MuiInputBase-input': {
							...inputStyles,
							padding: '10.5px 14px',
							borderRadius: '4px',
							backgroundColor:
								backgroundColor || theme.palette.background.default,
						},
						'& .MuiInputBase-root': {
							backgroundColor:
								backgroundColor || theme.palette.background.default,
							padding: '0',
						},
						input: {
							color: textColor || 'black',
						},
					}}
				>
					{selectValues?.length > 0 &&
						selectValues.map((option) =>
							option.displayName === 'Client Dashboard' ? (
								<MenuItem key={option.id} value={option.id}>
									Client Portal
								</MenuItem>
							) : (
								<MenuItem key={option.id} value={option.id}>
									{option.displayName}
								</MenuItem>
							)
						)}
				</Select>
			)}
			{helperText && error && (
				<CustomError theme={theme}>{helperText}</CustomError>
			)}
		</>
	);
}
