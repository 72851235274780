import React, { createContext, FC, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { decodeToken } from 'react-jwt';

/**
 * This is the base configuration for the React APP.
 * Any global variables such as API urls, route paths etc. should be set here.
 * Some of the settings are set dynamically during build time.
 * Those are set on the window object in index.html file.
 * For local development you can also use .env file, but make sure
 * that in that case you had set REACT_APP_LOCAL=true in it.
 */

/**
 * The config that will be used for local development
 * Make sure you set the needed env vars in your project
 */

const sideBar = {
	mainMenu: [
		{
			text: 'Create User',
			url: '/',
		},
		{
			text: 'Send invite email',
			url: '/send-set-password-email',
		},
		
	],
	secondaryMenu: [],
};

export type GlobalConfig = {
	appId: string;
	authority: string;
	redirectUri: string;
	scope: string;
	setToken: any;
	token: any;
	faToken: string;
	apimKey: string;
	sideBar: any;
	isAdmin: boolean;
	groupId: string;
	serverUrl: string;
};

const localConfig: GlobalConfig = {
	appId: process.env.REACT_APP_MSAL_APPID || '',
	authority: process.env.REACT_APP_MSAL_AUTHORITY || '',
	redirectUri: process.env.REACT_APP_MSAL_REDIRECTURI || '',
	scope: process.env.REACT_APP_MSAL_SCOPE || '',
	setToken: null,
	token: null,
	faToken: process.env.REACT_APP_FADEFAULT || '',
	apimKey: '',
	sideBar: {},
	isAdmin: false,
	groupId: process.env.REACT_APP_LOCAL_GROUPID || '',
	serverUrl: process.env.REACT_APP_FUNCTION_APP_API || ''
	
};
export const Config: React.Context<GlobalConfig> = createContext(
	{} as GlobalConfig
);

export const ConfigProvider: FC<any> = ({ children }) => {
	const config =
		process.env.REACT_APP_LOCAL === 'true'
			? localConfig
			: (window as any).ReactAppConfig;

	const [token, setToken] = useState<string | null>(null);
	const [isAdmin, setIsAdmin] = useState<boolean>(false);

	useEffect(() => {
		if (token) {
			const decoded = decodeToken<any>(token);
			setIsAdmin(decoded.roles.includes('Admin'));
		}
	}, [token]);

	const msalConfig = {
		auth: {
			clientId: config.appId,
			authority: `${config.authority}`, // 'Enter_the_Cloud_Instance_Id_Here/Enter_the_Tenant_Info_Here', // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
			redirectUri: config.redirectUri,
			// groupId:config.groupId,
		},
		cache: {
			cacheLocation: 'sessionStorage', // This configures where your cache will be stored
			storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
		},
	};
	
	const msalInstance = new PublicClientApplication(msalConfig);
	return (
		<Config.Provider
			value={
				/**
				 * If the local env is not set we use
				 * the options set on the window
				 */
				{
					...config,
					token,
					setToken,
					sideBar,
					isAdmin,
					scope: config.scope
				}
			}
		>
			<MsalProvider instance={msalInstance}>{children}</MsalProvider>
		</Config.Provider>
	);
};

ConfigProvider.propTypes = {
	children: PropTypes.node,
};
