import { type FC } from 'react';
import { type SxProps, TooltipProps, Typography } from '@mui/material';
import { truncateText } from '../../../helpers/truncateText';
import { Tooltip } from 'components/atoms/Tooltip';

type Props = {
	text: string;
	limit: number;
	tooltipStyle?: SxProps;
	textStyle?: SxProps;
	tooltipProps?: Omit<TooltipProps, 'title' | 'children'>;
	includeEllipsis?: boolean;
};

const TextWithTooltip: FC<Props> = (props) => {
	const {
		text,
		limit,
		tooltipStyle,
		textStyle,
		tooltipProps,
		includeEllipsis = true,
	} = props;

	if (text.length > limit) {
		return (
			<Tooltip
				title={text.replace(/"/g, '').replace(/'/g, '').replace(/\(|\)/g, '')}
				sx={tooltipStyle}
				{...tooltipProps}
			>
				<Typography sx={textStyle}>
					{truncateText(text, limit, '...', includeEllipsis)}
				</Typography>
			</Tooltip>
		);
	}
	return <Typography sx={textStyle}>{text}</Typography>;
};

export default TextWithTooltip;
