import {
	Box,
	Chip,
	Grid,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
	useTheme,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { read, utils } from 'xlsx';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import CheckIcon from '@mui/icons-material/Check';
import { ADB2CGroupChips } from 'components/molecules/adb2cGroupChips';

type Heading = {
	id: string;
	label: string;
	align: 'left' | 'right' | 'center';
	width: string;
	isSortable: boolean;
};

const headings: Heading[] = [
	{
		id: 'crm',
		label: 'CRM no.',
		align: 'left',
		width: '8%',
		isSortable: true,
	},

	{
		id: 'companyName',
		label: 'Company Name',
		align: 'left',
		width: '14%',
		isSortable: true,
	},
	{
		id: 'email',
		label: 'Email',
		align: 'left',
		width: '20%',
		isSortable: true,
	},
	{
		id: 'firstname',
		label: 'First Name',
		align: 'left',
		width: '14%',
		isSortable: true,
	},
	{
		id: 'surname',
		label: 'Last Name',
		align: 'left',
		width: '14%',
		isSortable: true,
	},

	{
		id: 'department',
		label: 'Location',
		align: 'left',
		width: '14%',
		isSortable: true,
	},
	{
		id: 'service',
		label: 'Service',
		align: 'left',
		width: '14%',
		isSortable: true,
	},
];
/*
function stringToColor(string: string) {
	let hash = 0;
	let i;

	/* eslint-disable no-bitwise */
	/*for (i = 0; i < string.length; i += 1) {
		hash = string.charCodeAt(i) + ((hash << 5) - hash);
	}

	let color = '#';

	for (i = 0; i < 3; i += 1) {
		const value = (hash >> (i * 8)) & 0xff;
		color += `00${value.toString(16)}`.slice(-2);
	}
	/* eslint-enable no-bitwise */
	/*color = '#04AFC3';
	return color;
}*/
/*
function stringAvatar(value: string): AvatarProps {
	const name = value === 'Client Dashboard' ? 'Client Portal' : value;
	const fullName = name.split(' ');
	if (fullName.length === 1) {
		return {
			sx: {
				bgcolor: stringToColor(name),
				height: '30px',
				width: '30px',
				color: '#000000',
				fontSize: '16px',
				fontWeight: '700',
				lineHeight: '20px',
			},
			children: `${name[0]}` || '',
		};
	} else if (fullName.length >= 2) {
		return {
			sx: {
				bgcolor: stringToColor(name),
				height: '30px',
				width: '30px',
				color: '#000000',
				fontSize: '16px',
				fontWeight: '700',
				lineHeight: '20px',
			},
			children: `${fullName[0][0]}${fullName[1][0]}` || '',
		};
	}
	return {
		sx: {
			bgcolor: stringToColor(name),
			height: '30px',
			width: '30px',
			color: '#000000',
			fontSize: '16px',
			fontWeight: '700',
			lineHeight: '20px',
		},
		children: '',
	};
}
*/
const BulkCreationForm = ({
	file,
	users,
	usersToSave,
	setUsersToSave,
	groups,
	errorsInFile,
	setErrorsInFile,
	bulkSaveSuccess,
}) => {
	const theme = useTheme();
	const [sheetNames, setSheetNames] = useState<any[]>([]);
	const [isLoading, setIsLoading] = useState(false);
	const [sheet, setSheet] = useState<string>('');
	const [data, setData] = useState<any[]>();
	const [columns, setColumns] = useState<any[]>([{}]);

	const [newUserCount, setNewUserCount] = useState<number>(0);

	const [validUserData, setValidUserData] = useState<any[]>([]);
	const [invalidUserData, setInvalidUserData] = useState<any[]>([]);
	const [existingUserData, setExistingUserData] = useState<any[]>([]);

	const readDataFromFile = (data) => {
		if (data) {
			const workbook = read(data);
			setSheetNames(workbook.SheetNames);
			if (!sheet) setSheet(sheetNames[0]);

			let tempSheetData = {};
			if (workbook) {
				workbook.SheetNames.forEach((sheet) => {
					const worksheet = workbook.Sheets[sheet];
					const jsonData = utils.sheet_to_json(worksheet, {
						blankrows: false,
						header: 1,
					});

					tempSheetData[sheet] = jsonData;
				});
			}

			setData(tempSheetData[sheet]);
			if (tempSheetData[sheet]) {
				let tempColumns = Object.keys(tempSheetData[sheet][0]).map(
					(value) => value
				);
				setColumns(tempColumns);
			}
		}
	};

	useEffect(() => {
		setValidUserData([]);
		setInvalidUserData([]);
		setExistingUserData([]);

		const readFile = async () => {
			setIsLoading(true);
			const data = await file?.arrayBuffer();
			readDataFromFile(data);
			setIsLoading(false);
		};
		readFile();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [file, sheet]);

	function repeatedEmail(e, arr) {
		let repeatCount = 0;
		arr.forEach((x) => {
			if (x[2] === e) {
				repeatCount++;
			}
		});
		if (repeatCount > 1) {
			return true;
		} else {
			return false;
		}
	}
	function validCRMLength(CRM) {
		if (CRM.toString().length < 3 || CRM.toString().length > 25) {
			return false;
		} else {
			return true;
		}
	}
	function validEmail(email) {
		let re = /\S+@\S+\.\S+/;

		if (!re.test(email)) {
			return false;
		} else {
			return true;
		}
	}

	function invalidGroup(groupArr) {
		let errorFound = false;

		groupArr.forEach((g) => {
			let search = g.toLowerCase().trim();
			if (search === 'client portal' || search === 'client dashboard') {
				search = 'Client Dashboard';
			} else if (search === 'mycontrolrisks' || search === 'mycr') {
				search = 'MyControlRisks';
			}
			let result = groups.filter((group: any) => {
				return group.displayName.toLowerCase() === search.toLowerCase();
			});
			if (result.length < 1) {
				errorFound = true;
			}
		});
		return errorFound;
	}

	useEffect(() => {
		if (data) {
			setNewUserCount(data.length - 1);

			data.slice(1).forEach((x) => {
				if (Object.keys(x).length < 7) {
					setInvalidUserData((oldArray) => [...oldArray, x]);
				} else {
					if (users.some((e) => e.email === x[2])) {
						setExistingUserData((oldArray) => [...oldArray, x]);
					} else {
						let email = x[2];
						let CRM = x[0];

						if (!validCRMLength(CRM)) {
							setInvalidUserData((oldArray) => [...oldArray, x]);
						} else if (!validEmail(email)) {
							setInvalidUserData((oldArray) => [...oldArray, x]);
						} else if (repeatedEmail(email, data.slice(1))) {
							setInvalidUserData((oldArray) => [...oldArray, x]);
						} else if (invalidGroup(x[6].split(','))) {
							setInvalidUserData((oldArray) => [...oldArray, x]);
						} else {
							setValidUserData((oldArray) => [...oldArray, x]);
						}
					}
				}
			});
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	useEffect(() => {
		if (
			invalidUserData.length === 0 &&
			existingUserData.length === 0 &&
			validUserData.length > 0
		) {
			setErrorsInFile(false);
		} else {
			setErrorsInFile(true);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [validUserData, errorsInFile, invalidUserData, existingUserData]);

	useEffect(() => {
		setUsersToSave(validUserData);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [validUserData]);

	function addStyleToError(key, value) {
		let returnVal = value;

		if (typeof value === 'undefined') {
			returnVal = (
				<Box
					sx={{ backgroundColor: '#E83C4E', width: '80px', height: '2px' }}
				></Box>
			);
		} else if (key === 'CRM') {
			if (!validCRMLength(value)) {
				returnVal = <p style={{ color: '#E83C4E' }}>{value}</p>;
			}
		} else if (key === 'email') {
			if (!validEmail(value)) {
				returnVal = <p style={{ color: '#E83C4E' }}>{value}</p>;
			}

			if (data) {
				if (repeatedEmail(value, data.slice(1))) {
					returnVal = <p style={{ color: '#E83C4E' }}>{value}</p>;
				}
			}
		} else if (key === 'service') {
			let groupArr = value.split(',');

			if (invalidGroup(groupArr)) {
				returnVal = <p style={{ color: '#E83C4E' }}>{value}</p>;
			} else {
				//returnVal = renderGroupChip('value');
			}
		}
		return returnVal;
	}

	function renderValidUserTable() {
		return (
			<Box
				sx={{
					display: 'flex',
				}}
			>
				<Box
					sx={{
						backgroundColor: '#FFF',
						flex: 1,
						overflow: 'auto',
					}}
				>
					{!isLoading ? (
						data &&
						columns && (
							<TableContainer
								component={Paper}
								elevation={3}
								sx={{
									borderRadius: '8px',
									maxHeight: '630px',
									scrollbarWidth: 'thin',
									scrollbarColor: '#7E8081 #CACDCD',
									overflow: 'overlay',
									'&::-webkit-scrollbar': {
										width: '10px',
										height: '10px',
										backgroundColor: '#CACDCD',
									},
									'&::-webkit-scrollbar-thumb': {
										backgroundColor: '#7E8081',
									},
									'&::-webkit-scrollbar-thumb:hover': {
										backgroundColor: '#7E8081',
									},
									'&::-webkit-scrollbar-corner': {
										backgroundColor: '#CACDCD',
									},
								}}
							>
								<Table
									sx={{
										minWidth: 650,
										tableLayout: 'fixed',
										'& .MuiTableCell-root': {
											overflow: 'hidden',
											textOverflow: 'ellipsis',
											whiteSpace: 'nowrap',
										},
									}}
									stickyHeader
									aria-label="simple table"
								>
									<TableHead
										sx={{
											backgroundColor: '#053747',
											color: theme.palette.common.white,
											fontWeight: 'bold',
											'& .MuiTableCell-root': {
												backgroundColor: '#053747',
												color: theme.palette.common.white,
												fontWeight: 'bold',
											},
										}}
									>
										<TableRow>
											{headings.map((heading) => (
												<TableCell
													key={heading.id}
													align={heading.align}
													style={{ width: heading.width }}
													sx={{
														color: theme.palette.common.white,
														fontWeight: 'bold',
													}}
												>
													{heading.label}
												</TableCell>
											))}
										</TableRow>
									</TableHead>
									<TableBody
										sx={{
											'& .MuiTableRow-root:nth-of-type(even)': {
												backgroundColor: '#F5F5F5',
											},
											'& .MuiTableRow-root:nth-of-type(odd)': {
												backgroundColor: '#FFFFFF',
											},
											'& .MuiTableRow-root:hover': {
												backgroundColor: '#CACDCD',
											},
										}}
									>
										{validUserData &&
											validUserData.map((row, index) => (
												<TableRow key={`row-valid-${index}`}>
													{row.map((value, index) => (
														<TableCell
															component="th"
															scope="row"
															key={`cell-valid-${index}`}
														>
															{index !== 6 ? (
																<>{value}</>
															) : (
																<>
																	<ADB2CGroupChips
																		nameString={value}
																		renderWithString={true}
																		groups={[]}
																	/>
																</>
															)}
														</TableCell>
													))}
												</TableRow>
											))}
									</TableBody>
								</Table>
							</TableContainer>
						)
					) : (
						<p>Loading...</p>
					)}
				</Box>
			</Box>
		);
	}

	function renderInvalidUserTable() {
		return (
			<Box
				sx={{
					display: 'flex',
				}}
			>
				<Box
					sx={{
						backgroundColor: '#FFF',
						flex: 1,
						overflow: 'auto',
					}}
				>
					{!isLoading ? (
						data &&
						columns && (
							<TableContainer
								component={Paper}
								elevation={3}
								sx={{
									borderRadius: '8px',
									maxHeight: '630px',
									scrollbarWidth: 'thin',
									scrollbarColor: '#7E8081 #CACDCD',
									overflow: 'overlay',
									'&::-webkit-scrollbar': {
										width: '10px',
										height: '10px',
										backgroundColor: '#CACDCD',
									},
									'&::-webkit-scrollbar-thumb': {
										backgroundColor: '#7E8081',
									},
									'&::-webkit-scrollbar-thumb:hover': {
										backgroundColor: '#7E8081',
									},
									'&::-webkit-scrollbar-corner': {
										backgroundColor: '#CACDCD',
									},
								}}
							>
								<Table
									sx={{
										minWidth: 650,
										tableLayout: 'fixed',
										'& .MuiTableCell-root': {
											overflow: 'hidden',
											textOverflow: 'ellipsis',
											whiteSpace: 'nowrap',
										},
									}}
									stickyHeader
									aria-label="simple table"
								>
									<TableHead
										sx={{
											backgroundColor: '#053747',
											color: theme.palette.common.white,
											fontWeight: 'bold',
											'& .MuiTableCell-root': {
												backgroundColor: '#053747',
												color: theme.palette.common.white,
												fontWeight: 'bold',
											},
										}}
									>
										<TableRow>
											{headings.map((heading) => (
												<TableCell
													key={heading.id}
													align={heading.align}
													style={{ width: heading.width }}
													sx={{
														color: theme.palette.common.white,
														fontWeight: 'bold',
													}}
												>
													{heading.label}
												</TableCell>
											))}
										</TableRow>
									</TableHead>
									<TableBody
										sx={{
											'& .MuiTableRow-root:nth-of-type(even)': {
												backgroundColor: '#F5F5F5',
											},
											'& .MuiTableRow-root:nth-of-type(odd)': {
												backgroundColor: '#FFFFFF',
											},
											'& .MuiTableRow-root:hover': {
												backgroundColor: '#CACDCD',
											},
										}}
									>
										{invalidUserData &&
											invalidUserData.map((row, index) => (
												<TableRow key={`row-invalid-${index}`}>
													<TableCell
														component="th"
														scope="row"
														key={`cell-invalid-0`}
													>
														{addStyleToError('CRM', row[0])}
													</TableCell>
													<TableCell
														component="th"
														scope="row"
														key={`cell-invalid-1`}
													>
														{addStyleToError('companyName', row[1])}
													</TableCell>
													<TableCell
														component="th"
														scope="row"
														key={`cell-invalid-2`}
													>
														{addStyleToError('email', row[2])}
													</TableCell>
													<TableCell
														component="th"
														scope="row"
														key={`cell-invalid-3`}
													>
														{addStyleToError('firstName', row[3])}
													</TableCell>
													<TableCell
														component="th"
														scope="row"
														key={`cell-invalid-4`}
													>
														{addStyleToError('lastName', row[4])}
													</TableCell>
													<TableCell
														component="th"
														scope="row"
														key={`cell-invalid-5`}
													>
														{addStyleToError('location', row[5])}
													</TableCell>
													<TableCell
														component="th"
														scope="row"
														key={`cell-invalid-6`}
													>
														{addStyleToError('service', row[6])}
													</TableCell>
												</TableRow>
											))}
									</TableBody>
								</Table>
							</TableContainer>
						)
					) : (
						<p>Loading...</p>
					)}
				</Box>
			</Box>
		);
	}

	function renderExistingUserTable() {
		return (
			<Box
				sx={{
					display: 'flex',
				}}
			>
				<Box
					sx={{
						backgroundColor: '#FFF',
						flex: 1,
						overflow: 'auto',
					}}
				>
					{!isLoading ? (
						data &&
						columns && (
							<TableContainer
								component={Paper}
								elevation={3}
								sx={{
									borderRadius: '8px',
									maxHeight: '630px',
									scrollbarWidth: 'thin',
									scrollbarColor: '#7E8081 #CACDCD',
									overflow: 'overlay',
									'&::-webkit-scrollbar': {
										width: '10px',
										height: '10px',
										backgroundColor: '#CACDCD',
									},
									'&::-webkit-scrollbar-thumb': {
										backgroundColor: '#7E8081',
									},
									'&::-webkit-scrollbar-thumb:hover': {
										backgroundColor: '#7E8081',
									},
									'&::-webkit-scrollbar-corner': {
										backgroundColor: '#CACDCD',
									},
								}}
							>
								<Table
									sx={{
										minWidth: 650,
										tableLayout: 'fixed',
										'& .MuiTableCell-root': {
											overflow: 'hidden',
											textOverflow: 'ellipsis',
											whiteSpace: 'nowrap',
										},
									}}
									stickyHeader
									aria-label="simple table"
								>
									<TableHead
										sx={{
											backgroundColor: '#053747',
											color: theme.palette.common.white,
											fontWeight: 'bold',
											'& .MuiTableCell-root': {
												backgroundColor: '#053747',
												color: theme.palette.common.white,
												fontWeight: 'bold',
											},
										}}
									>
										<TableRow>
											{headings.map((heading) => (
												<TableCell
													key={heading.id}
													align={heading.align}
													style={{ width: heading.width }}
													sx={{
														color: theme.palette.common.white,
														fontWeight: 'bold',
													}}
												>
													{heading.label}
												</TableCell>
											))}
										</TableRow>
									</TableHead>
									<TableBody
										sx={{
											'& .MuiTableRow-root:nth-of-type(even)': {
												backgroundColor: '#F5F5F5',
											},
											'& .MuiTableRow-root:nth-of-type(odd)': {
												backgroundColor: '#FFFFFF',
											},
											'& .MuiTableRow-root:hover': {
												backgroundColor: '#CACDCD',
											},
										}}
									>
										{existingUserData &&
											existingUserData.map((row, index) => (
												<TableRow key={`row-existing-${index}`}>
													{row.map((value, index) => (
														<TableCell
															component="th"
															scope="row"
															key={`cell-existing-${index}`}
														>
															{index !== 6 ? (
																<>{value}</>
															) : (
																<>
																	{value}
																	<ADB2CGroupChips
																		nameString={value}
																		renderWithString={true}
																		groups={[]}
																	/>
																</>
															)}
														</TableCell>
													))}
												</TableRow>
											))}
									</TableBody>
								</Table>
							</TableContainer>
						)
					) : (
						<p>Loading...</p>
					)}
				</Box>
			</Box>
		);
	}

	return (
		<Grid sx={{ marginTop: '20px' }} container spacing={8} direction="column">
			{invalidUserData.length === 0 &&
			existingUserData.length === 0 &&
			validUserData.length > 0 ? (
				<>
					<Grid item>
						<Grid container direction="row">
							{bulkSaveSuccess ? (
								<></>
							) : (
								<>
									<Grid container direction="row" alignItems="center">
										<CheckIcon
											color="primary"
											sx={{ width: '20px', height: '20px', color: '#008475' }}
										/>
										<Typography
											sx={{ margin: '5px', color: '#008475', fontSize: '12px' }}
										>
											{' '}
											Users to be created{' '}
										</Typography>
										<Chip
											label={`${
												validUserData?.length >= 0
													? `${validUserData.length} / `
													: ''
											}${validUserData.length}`}
											sx={{
												fontSize: '12px',
												lineHeight: '22px',
												fontWeight: '400',
												backgroundColor: '#008475',
												fontFamily: 'Inter, sans-serif',
												width: '70px',
												height: '20px',
												marginTop: '1px',
												borderRadius: '40px',
											}}
											color="primary"
											variant="filled"
											aria-label="user count"
										/>
									</Grid>
								</>
							)}
						</Grid>
						{renderValidUserTable()}
					</Grid>
				</>
			) : (
				<>
					<Grid item>
						<Grid container direction="row">
							<Grid container direction="row" alignItems="center">
								<HelpOutlineIcon
									color="primary"
									sx={{ width: '20px', height: '20px', color: '#E83C4E' }}
								/>
								<Typography
									sx={{ color: '#E83C4E', fontSize: '12px', margin: '5px' }}
								>
									{' '}
									The following users have invalid fields{' '}
								</Typography>
								<Chip
									label={`${
										invalidUserData?.length >= 0
											? `${invalidUserData?.length} / `
											: ''
									}${newUserCount}`}
									sx={{
										fontSize: '12px',
										lineHeight: '22px',
										fontWeight: '400',
										backgroundColor: '#E83C4E',
										fontFamily: 'Inter, sans-serif',
										width: '70px',
										height: '20px',
										marginTop: '1px',
										borderRadius: '40px',
									}}
									color="primary"
									variant="filled"
									aria-label="user count"
								/>
							</Grid>
						</Grid>
						{renderInvalidUserTable()}
					</Grid>

					<Grid item>
						<Grid container direction="row">
							<Grid container direction="row" alignItems="center">
								<ReportProblemIcon
									color="primary"
									sx={{ width: '20px', height: '20px', color: '#BA0E68' }}
								/>
								<Typography
									sx={{ margin: '5px', color: '#BA0E68', fontSize: '12px' }}
								>
									{' '}
									The following users already exist{' '}
								</Typography>
								<Chip
									label={`${
										existingUserData?.length >= 0
											? `${existingUserData?.length} / `
											: ''
									}${newUserCount}`}
									sx={{
										fontSize: '12px',
										lineHeight: '22px',
										fontWeight: '400',
										backgroundColor: '#BA0E68',
										fontFamily: 'Inter, sans-serif',
										width: '70px',
										height: '20px',
										marginTop: '1px',
										borderRadius: '40px',
									}}
									color="primary"
									variant="filled"
									aria-label="user count"
								/>
							</Grid>
						</Grid>
						{renderExistingUserTable()}
					</Grid>

					<Grid item>
						<Grid container direction="row">
							<Grid container direction="row" alignItems="center">
								<CheckIcon
									color="primary"
									sx={{ width: '20px', height: '20px', color: '#008475' }}
								/>
								<Typography
									sx={{ margin: '5px', color: '#008475', fontSize: '12px' }}
								>
									{' '}
									Users to be created{' '}
								</Typography>
								<Chip
									label={`${
										validUserData?.length >= 0
											? `${validUserData.length} / `
											: ''
									}${newUserCount}`}
									sx={{
										fontSize: '12px',
										lineHeight: '22px',
										fontWeight: '400',
										backgroundColor: '#008475',
										fontFamily: 'Inter, sans-serif',
										width: '70px',
										height: '20px',
										marginTop: '1px',
										borderRadius: '40px',
									}}
									color="primary"
									variant="filled"
									aria-label="user count"
								/>
							</Grid>
						</Grid>

						{renderValidUserTable()}
					</Grid>
				</>
			)}
		</Grid>
	);
};

export default BulkCreationForm;
