import { createTheme } from '@mui/material/styles';
import red from '@mui/material/colors/red';
import yellow from '@mui/material/colors/yellow';
import grey from '@mui/material/colors/grey';

const theme = () =>
	createTheme({
		palette: {
			mode: 'light',
			primary: {
				main: '#053747',
				light: '#ffffff',
				dark: '#000000',
				contrastText: '#fff',
			},
			secondary: {
				light: '#8DD5D2',
				main: '#306671',
				dark: '#CACDCD',
				contrastText: grey[50],
			},
			error: {
				light: red[400],
				main: '#E83C4E',
				dark: red[300],
				contrastText: grey[800],
			},
			success: {
				main: '#008475',
			},
			warning: {
				main: yellow[500],
				contrastText: grey[800],
			},
			info: {
				light: '#04AFC3',
				main: '#336473',
				dark: '#595958',
				contrastText: '#7E8081',
			},
			text: {
				primary: grey[900],
				secondary: '#99B1B9',
				disabled: '#C0CDD1',
			},
			action: {
				active: grey[600],
				activatedOpacity: 1,
				disabled: grey[700],
				disabledBackground: grey[200],
				hover: grey[300],
				hoverOpacity: 0.7,
				focus: grey[600],
				focusOpacity: 1,
				selected: grey[300],
				selectedOpacity: 0,
			},
			background: {
				default: '#ffffff',
				// this also controls table rows background color
				paper: grey[200],
			},
			common: {
				black: 'black',
				white: 'white',
			},
			tonalOffset: 0.2,
		},
		// default values
		// breakpoints: {
		// 	values: {
		// 		xs: 0,
		// 		sm: 600,
		// 		md: 900,
		// 		lg: 1200,
		// 		xl: 1536,
		// 	},
		// },
		typography: {
			fontSize: 12,
			fontWeightLight: 300,
			fontWeightMedium: 500,
			fontWeightBold: 700,
			fontWeightRegular: 0,
			fontFamily: ['Open Sans', 'sans-serif'].join(','),
		},
		components: {
			MuiCssBaseline: {
				styleOverrides: `
					@font-face {
						font-family: 'SF Pro Text';
						src: url("/sf-text-light.woff") format('woff');
						font-weight: 300;
						font-style: normal;
					}
				
					@font-face {
						font-family: 'SF Pro Text';
						src: url("/sf-text-regular.woff") format('woff');
						font-weight: 400;
						font-style: normal;
					}
				`,
			},
			MuiListItemButton: {
				styleOverrides: {
					root: ({ ownerState, theme }) => ({
						':hover': {
							backgroundColor: theme.palette.secondary.main,
							color: theme.palette.primary.main,

							'.MuiListItemIcon-root': {
								color: theme.palette.primary.main,
							},
						},
						'&.Mui-selected': {
							'&:hover': {
								backgroundColor: theme.palette.secondary.main,
							},
							backgroundColor: theme.palette.secondary.main,
							color: theme.palette.primary.main,

							'.MuiListItemIcon-root': {
								color: theme.palette.primary.main,
							},
						},
					}),

					// selected: ({ ownerState, theme }) => ({
					//   backgroundColor: theme.palette.secondary.main,
					//   color: theme.palette.primary.main,

					//   ".MuiListItemIcon-root": {
					//     color: theme.palette.primary.main
					//   },

					//   borderRadius: theme.spacing(2)
					// })
				},
			},
		},
		// also can convert pixel to rem
		spacing: (factor: number) => `${0.5 * factor}rem`,
	});

export default theme;
