import { Box, useTheme, Link } from '@mui/material';
import { memo } from 'react';

const Footer = () => {
	const theme = useTheme();
	return (
		<Box
			component="footer"
			sx={{
				py: '38px',
				px: {
					xs: '10px',
					md: '50px',
				},
				backgroundColor: '#003D50',
				display: 'flex',
				flexDirection: 'row',
				alignItems: 'center',
				justifyContent: 'space-between',
			}}
		>
			<Box
				component="span"
				sx={{
					color: theme.palette.common.white,
					fontSize: '15px',
					lineHeight: '24px',
					fontWeight: '400',
					letterSpacing: '-0.225px',
				}}
			>
				© Control Risks 2023.{' '}
			</Box>
			<Link
				href="https://www.controlrisks.com/"
				target="_blank"
				rel="noopener noreferrer"
				sx={{
					color: '#8DD5D2',
					fontSize: '15px',
					lineHeight: '24px',
					fontWeight: '500',
					letterSpacing: '-0.225px',
				}}
			>
				controlrisks.com
			</Link>
		</Box>
	);
};

export default memo(Footer);
