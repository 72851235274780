import React from 'react';
import { Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { SnackBarProps } from './types';

const MAlert = React.forwardRef(function Alert(props: any, ref: any) {
	return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function Alert({
	message,
	severity,
	isOpen,
	setIsOpen,
	vertical = 'top',
	horizontal = 'center',
}: SnackBarProps) {
	return (
		<>
			<Snackbar
				anchorOrigin={{ vertical, horizontal }}
				open={isOpen}
				autoHideDuration={2000}
				onClose={() => setIsOpen(false)}
			>
				<MAlert severity={severity} sx={{ width: '100%' }}>
					{message}
				</MAlert>
			</Snackbar>
		</>
	);
}
