import { useReducer, useEffect, useState, useContext } from 'react';
import { Config } from '../../config';

const dataFetchReducer = (state: any, action: any) => {
	switch (action.type) {
		case 'FETCH_INIT':
			return {
				...state,
				loading: true,
			};
		case 'FETCH_SUCCESS':
			return {
				...state,
				loading: false,
				error: false,
				data: action.payload,
			};
		case 'FETCH_FAILURE':
			return {
				...state,
				loading: false,
				error: true,
			};
		case 'FETCH_RESET':
			return {
				loading: false,
				data: null,
				error: false,
			};
		default:
			throw new Error(
				`Unknown action type received 
        for dataFetchReducer.
        Please make sure you are passing
        one of the following actions:
          * FETCH_INIT
          * FETCH_SUCCESS
          * FETCH_FAILURE
          :`
			);
	}
};

type useFetchReturn<T> = {
	loading: boolean;
	data: T | null;
	error: boolean;
	reset: () => void;
	fetchLazy: (url: string, options: any, refetch:boolean) => void;
	reload: () => void;
}

export const useFetch = <T extends any>(url: any, options: any, responseBody: any = null):useFetchReturn<T> => {
	const initialState = {
		loading: false,
		data: null,
		error: false,
	};
	const { token, serverUrl, faToken, apimKey } = useContext(Config);
	const initialOptions = options;
	const [state, dispatch] = useReducer(dataFetchReducer, initialState);
	const [fetchUrl, setFetchUrl] = useState(url);
	const [body, setBody] = useState(options?.body);
	const [method, setMethod] = useState(options?.method);
	const Authorization = `Bearer ${token}`;
	const contentType = options?.headers?.['Content-Type'];
	const onSuccess = options?.onSuccess;
	const onError = options?.onError;
	const [reload, setReload] = useState(0);

	useEffect(() => {
		if (fetchUrl) {
			dispatch({ type: 'FETCH_INIT' });
			fetch(serverUrl + fetchUrl, {
				method,
				headers: {
				  ...(Authorization ? { Authorization } : {}),
				  ...(faToken ? { 'x-functions-key': faToken } : {}),
				  ...(apimKey ? { 'Ocp-Apim-Subscription-Key': apimKey } : {}),
				  ...(contentType ? { 'Content-Type': contentType } : {}),
				},
				...(body ? { body } : {}),
			  })
				.then((response: any) => {
					if (response.status !== 200 && response.status !== 201) {
						if (response.status === 500) {
							//navigate('/error');
						}
						if (response.status === 404) {
							//navigate('/*');
						}
						if (response.status === 409) {
							throw new Error(`
            User already exists`);
						}
						if (onError) {
							onError();
						}
						throw new Error(`
            Failed request with status: ${response.status}
            `);
					}
					const body = responseBody ? responseBody : 'json';
					return response[body]();
				})
				.then((data) => {
					if (onSuccess) {
						onSuccess(data);
					}
					dispatch({ type: 'FETCH_SUCCESS', payload: data });
				})
				.catch((error) => {
					if (process.env.NODE_ENV === 'development') {
						console.log(error);
					}
					dispatch({ type: 'FETCH_FAILURE' });

					if (onError) {
						onError(error);
					}
				});
		}

		// eslint-disable-next-line
	}, [
		fetchUrl,
		body,
		method,
		contentType,
		reload,
		Authorization,
		responseBody,
	]);

	return {
		...state,
		reset: () => {
			setFetchUrl('');
			dispatch({ type: 'FETCH_RESET' });
		},
		fetchLazy: (url: string, options: any = {}, refetch = true) => {
			if (options.method) {
				setMethod(options.method);
			}
			if (options.body) {
				setBody(options.body);
			} else {
				setBody(initialOptions?.body);
			}

			if (refetch) {
				setReload(reload + 1);
			}
			setFetchUrl(url);
		},
		reload: () => {
			setReload(reload + 1);
		},
	};
};

export default useFetch;
